import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import axios from 'axios'
import { insertUserApiAccessInfo } from 'helpers/userApiAccessInfo'

import { Provider } from 'react-redux'
import { configureStore } from './redux/store'
import { isUserAuthenticated } from './helpers/authUtils'
import { Redirect } from 'react-router-dom'

axios.defaults.headers.common['x-access-token'] = `${sessionStorage.getItem('token')}`
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.withCredentials = true

axios.interceptors.request.use(
  (config) => {
    // Request 보내기 전에 수행됨
    // config 자체에는 request의 정보가 다 들어있음. url, headers, data 등
    // config를 반환하거나, Promise.resolve(config)으로 반환하면 되는 듯
    // console.log(config)

    // alert('인터셉터' + JSON.stringify(config))
    const tokenValidation = isUserAuthenticated() // 토큰 만료여부 검증
    if (tokenValidation === false) {
      if (config.url === '/user/login') {
        return config
      }
      if (config.url === '/user/getCertifiedPage') {
        return config
      }
      if (config.url != '/user/login') {
        return <Redirect to={{ pathname: '/account/login' }} />
      }
    }
    return config
  },
  (error) => {
    // Request 수행 중 오류 발생 시 수행됨
    return Promise.reject(error)
  },
)

// 응답 인터셉터 추가
axios.interceptors.response.use(
  (config) => {
    // console.log(config)
    const configUrl = config.config.url
    const checkUrl = '/api/userApiAccessInfo/insertUserApiAccessInfo'
    if (configUrl != checkUrl) {
      // insertUserApiAccessInfo(config.config.url) // API 이용이력 남기기
    }
    return config
  },
  (error) => {
    // Request 수행 중 오류 발생 시 수행됨
    console.log('axios 수행 후, 에러가 발생하였습니다. 해당 조치로 로그인 페이지 이동하였습니다.')
    return <Redirect to={{ pathname: '/account/login' }} />
  },
)

ReactDOM.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
