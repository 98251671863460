import axios from 'axios'
import {getUserId, getUserPartnerCompanyId2, getUserType, userCompany} from 'helpers/getUserInfo'
import dayjs from 'dayjs'
import {userToast} from "helpers/userToast";
import _ from 'lodash'


const BASE_URL = '/apps/businessManagement/monthlyClosing/currentKpi'

export const getDailySalesAmount0911 = async ({ param = '', dataBasket = {} }) => {
    const companyCode = userCompany()
    const userId = getUserId()
    const userType = getUserType()
    const userTypeCPartnerCompanyId = getUserPartnerCompanyId2()

    const params = {
        companyCode,
        userId,
        param,
        dataBasket,
        userType,
        userTypeCPartnerCompanyId,
    }

    const vv = await axios.post(`${BASE_URL}/getDailySalesAmount0911`, {
        params,
    })

    if (vv?.data?.success) {
        const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
        return filteredArray
    }
}


export const getDailySujuInfo0911 = async ({ param = '', dataBasket = {} }) => {
    const companyCode = userCompany()
    const userId = getUserId()
    const userType = getUserType()
    const userTypeCPartnerCompanyId = getUserPartnerCompanyId2()

    const params = {
        companyCode,
        userId,
        param,
        dataBasket,
        userType,
        userTypeCPartnerCompanyId,
    }

    const vv = await axios.post(`${BASE_URL}/getDailySujuInfo0911`, {
        params,
    })

    if (vv?.data?.success) {
        const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
        return filteredArray
    }
}

export const getSujuCancelCheck0912 = async ({ param = '', dataBasket = {} }) => {
    const companyCode = userCompany()
    const userId = getUserId()
    const userType = getUserType()
    const userTypeCPartnerCompanyId = getUserPartnerCompanyId2()

    const params = {
        companyCode,
        userId,
        param,
        dataBasket,
        userType,
        userTypeCPartnerCompanyId,
    }

    const vv = await axios.post(`${BASE_URL}/getSujuCancelCheck0912`, {
        params,
    })

    if (vv?.data?.success) {
        const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
        return filteredArray
    }
}

export const getSujuCancelMaterialOrderCheck0915 = async ({ param = '', dataBasket = {} }) => {
    const companyCode = userCompany()
    const userId = getUserId()
    const userType = getUserType()
    const userTypeCPartnerCompanyId = getUserPartnerCompanyId2()

    const params = {
        companyCode,
        userId,
        param,
        dataBasket,
        userType,
        userTypeCPartnerCompanyId,
    }

    const vv = await axios.post(`${BASE_URL}/getSujuCancelMaterialOrderCheck0915`, {
        params,
    })

    if (vv?.data?.success) {
        const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
        return filteredArray
    }
}


export const getSalesPriceCheck0913 = async ({ param = '', dataBasket = {} }) => {
    const companyCode = userCompany()
    const userId = getUserId()
    const userType = getUserType()
    const userTypeCPartnerCompanyId = getUserPartnerCompanyId2()

    const params = {
        companyCode,
        userId,
        param,
        dataBasket,
        userType,
        userTypeCPartnerCompanyId,
    }

    const vv = await axios.post(`${BASE_URL}/getSalesPriceCheck0913`, {
        params,
    })

    if (vv?.data?.success) {
        const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
        return filteredArray
    }
}


export const getDailyReturnInfo0911 = async ({ param = '', dataBasket = {} }) => {
    const companyCode = userCompany()
    const userId = getUserId()
    const userType = getUserType()
    const userTypeCPartnerCompanyId = getUserPartnerCompanyId2()

    const params = {
        companyCode,
        userId,
        param,
        dataBasket,
        userType,
        userTypeCPartnerCompanyId,
    }

    const vv = await axios.post(`${BASE_URL}/getDailyReturnInfo0911`, {
        params,
    })

    if (vv?.data?.success) {
        const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
        return filteredArray
    }
}


export const getDailyResultMyInfo0912 = async ({ param = '', dataBasket = {} }) => {
    const companyCode = userCompany()
    const userId = getUserId()
    const userType = getUserType()
    const userTypeCPartnerCompanyId = getUserPartnerCompanyId2()

    const params = {
        companyCode,
        userId,
        param,
        dataBasket,
        userType,
        userTypeCPartnerCompanyId,
    }

    const vv = await axios.post(`${BASE_URL}/getDailyResultMyInfo0912`, {
        params,
    })

    if (vv?.data?.success) {
        const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
        return filteredArray
    }
}
