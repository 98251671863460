import { toast } from 'react-toastify'

const position = 'top-center'

export const userToast = (type, content, time = 1) => {
  switch (type) {
    case 'success':
      //성공
      toast.success(content, { position, autoClose: time * 1000 })
      break
    case 'error':
      //에러
      toast.error(content, { position, autoClose: time * 1300, draggable: false })
      break
    case 'warning':
      //테스트
      toast.warning(content, { position, autoClose: time * 1300 })
      break
    case 'info':
      toast.info(content, { position, autoClose: time * 1000 })
      break
    case '':
      //성공(쉽게 하기 위해서)
      toast.success(content, { position, autoClose: time * 1000 })
      break
    default:
      toast(content, { position, autoClose: time * 1000 })
  }
}

export const mobileToast = (type, content, time = 1) => {
  const addStyle = {
    width: '72vw',
    maxWidth: '312px',
    margin: '0 auto',
    position: 'relative',
    top: '10vh',
  }
  switch (type) {
    case 'success':
      return toast.success(content, { position, autoClose: time * 1000, style: addStyle })
    case 'error':
      return toast.error(content, { position, autoClose: time * 1300, draggable: false, style: addStyle })
    case 'warning':
      return toast.warning(content, { position, autoClose: time * 1300, style: addStyle })
    case 'info':
      return toast.info(content, { position, autoClose: time * 1000, style: addStyle })
    default:
      toast(content, { position, autoClose: time * 1000, style: addStyle })
  }
}
