import _ from "lodash";
import React from "react";


export const eachTableTdAmount = ({ tableList=[], year = 0, month = 0 }) => {
    const CL1 = tableList?.filter((f) => f.subul_year === year && f.subul_month === month)
    const CL2 = _.sumBy(CL1,"out_amount") ?? 0
    return _.ceil(CL2 / 1000000,0)
}

export const eachTableTdSubTotal = ({tableList=[], year = 0, }) => {
    const CL1 = tableList?.filter((f) => f.subul_year === year )
    const totalAmount = _.sumBy(CL1,"out_amount")
    return _.ceil(totalAmount / 1000000,0)
}

export const eachTableTdRatio = ({ top = 0, bottom = 0 }) => {
    if (top > 0 && bottom > 0) {
        const tempValue = (top / bottom - 1) * 100
        return <td style={{ color: tempValue > 0 ? 'blue' : 'red', backgroundColor:"rgba(211, 211, 211, 0.22)" }}>{_.floor(tempValue, 1).toLocaleString()}</td>
    }
    return <td style={{ backgroundColor:"rgba(211, 211, 211, 0.22)" }}>0</td>
}

export const getDataByMonth = ({xCategory,baseTable,year=0})=>{

    let returnArray = []
    let CL1 = baseTable.filter((f)=>f.subul_year === year) ?? []

    // 월별
    xCategory.map((v)=>{
        if(v > 0){
            const tempArray = CL1.filter((f)=> f.subul_month === v)
            const tempValue1 = (_.sumBy(tempArray,"out_amount") / 1000000) ?? 0
            const tempValue2 = _.floor(tempValue1,0)
            returnArray.push(tempValue2)
        }
    })


    return returnArray
}