import axios from 'axios'
import {getUserId} from "helpers/getUserInfo";
import {userToast} from "helpers/userToast";

const sessionObjName = "pageInfoLog"


// sessionStorage.setItem(sessionObjName, JSON.stringify(initPageInfo))


export const getNowPageInfo0214 =  async () => {
    const urlPath = document.location.pathname
    const urlPathApiResult = await axios.post(`/user/getUrlMenuName0214`, {urlPath})
    let menuNameMaster = ''
    if(urlPathApiResult?.data){
        menuNameMaster = urlPathApiResult?.data[0]?.menu_name
    }else{
        menuNameMaster = "수주등록"
    }


    if(!JSON.parse(sessionStorage.getItem(sessionObjName))?.beforePage){
        let initPageInfo = {
            beforePage:"",
            nowPage:"",
            insertYn :0,
        }
        sessionStorage.setItem(sessionObjName, JSON.stringify(initPageInfo))
    }


    const pageInfo = JSON.parse(sessionStorage.getItem(sessionObjName));
    if(pageInfo?.beforePage === ""){
        pageInfo.beforePage = `${menuNameMaster}`
        pageInfo.nowPage    = `${menuNameMaster}`
        pageInfo.insertYn    = 0
        sessionStorage.setItem(sessionObjName, JSON.stringify(pageInfo))
    }else{
        pageInfo.beforePage =  pageInfo.nowPage
        pageInfo.nowPage    = `${menuNameMaster}`
        pageInfo.insertYn    = 1
        sessionStorage.setItem(sessionObjName, JSON.stringify(pageInfo))
    }

    return pageInfo
}

export const insertSmartFactoryApiResult0214 = async () => {
    const pageInfo = await getNowPageInfo0214()
    const userId = getUserId()
    const {beforePage,nowPage} = pageInfo ?? ""

    if(pageInfo.insertYn === 0 || pageInfo.beforePage === pageInfo.nowPage) return console.log("등록 대상 제외")


    const insertLogFNC = async (pageName)=>{
        const res = await axios.get(`/user/smartFactoryApiResult`, { params: { pageName,userId } })
        // console.log("스마트공장 접속 이력__등록 완료",res.data)
    }

    if(beforePage === nowPage && nowPage !== ""){  // 첫 화면 접속 시
        const insertPageName = `접속_${nowPage}`
        insertLogFNC(insertPageName)
    }else{
        const insertPageName1 = `종료_${beforePage}`
        insertLogFNC(insertPageName1)

        const insertPageName2 = `접속_${nowPage}`
        insertLogFNC(insertPageName2)

    }


}

