import React, {useEffect, useState} from 'react';
import {userToast} from "helpers/userToast";
import {getDailyResultMyInfo0912, getDailyReturnInfo0911, getDailySalesAmount0911, getDailySujuInfo0911} from "../api";
import _ from 'lodash'
import {getRatio0911} from "../function";
import Style from '../style/dashboard.module.scss'
import {Table} from "reactstrap";

const ResultMyDaily0912 = ({options={}}) => {

    const [tableList,setTableList] = useState([])

    const initFnc= async ()=>{
        const apiResult = await getDailyResultMyInfo0912({param:"",dataBasket:{...options}})
        console.log("apiResult_0912_1541_",apiResult)
        setTableList(apiResult)
    }

    const getTableTd = ({classType="",subulYear=0,columnName="",precision=0})=>{
        if(!tableList.length > 0) return 0
        const CL1 = _.cloneDeep(tableList)
        const CL2 = CL1.filter((f)=> f.subul_year === subulYear && f.class_type === classType)

        if(CL2.length > 0){
            return _.floor(CL2[0][columnName],precision) ?? 0
        }else{
            return 0
        }
    }


    const getTableSubtotalTd = ({classType="" ,columnName=""})=>{
        const CL1 = _.cloneDeep(tableList)
        const CL2 = CL1.filter((f)=> f.class_type === classType)

        if(CL2.length > 0){
            const tempValue = _.sumBy(CL2,columnName) ?? 0
            return _.floor(tempValue,0) ?? 0
        }else{
            return 0
        }
    }

    useEffect(()=>{
        if(options.thisYear > 0){
            initFnc()
        }
    },[options])


    return (
        <div className={`${Style.wrapper}`}>
            <span className={`${Style.subTitle}`}>자사 실적 현황</span>
            <Table bordered={true}  size={"sm"}>
                <colgroup>
                    <col width="2%" />
                    <col width="4%" />
                    <col width="4%" />
                </colgroup>
                    <thead>
                    <tr>
                        <th>구 분</th>
                        <th>당 일</th>
                        <th>누 계</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>계획</td>
                        <td>{getTableTd({classType:"당일",subulYear:options.lastYear,columnName:"product_length"}).toLocaleString()}</td>
                        <td>{getTableTd({classType:"누계",subulYear:options.lastYear,columnName:"product_length"}).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td>실적</td>
                        <td>{getTableTd({classType:"당일",subulYear:options.thisYear,columnName:"product_length"}).toLocaleString()}</td>
                        <td>{getTableTd({classType:"누계",subulYear:options.thisYear,columnName:"product_length"}).toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td>달성률</td>
                        <td>
                            {getRatio0911({
                                top:getTableTd({classType:"당일",subulYear:options.thisYear,columnName:"product_length"}),
                                bottom:getTableTd({classType:"당일",subulYear:options.lastYear,columnName:"product_length"}),
                            })}%
                        </td>
                        <td>
                            {getRatio0911({
                                top:getTableTd({classType:"누계",subulYear:options.thisYear,columnName:"product_length"}),
                                bottom:getTableTd({classType:"누계",subulYear:options.lastYear,columnName:"product_length"}),
                            })}%
                        </td>
                    </tr>
                    <tr>
                        <td>생산성</td>
                        <td>{getTableTd({classType:"생산성_당일",subulYear:options.thisYear,columnName:"driving_rpm"})}rpm</td>
                        <td>{getTableTd({classType:"생산성_누계",subulYear:options.thisYear,columnName:"driving_rpm"})}rpm</td>
                    </tr>
                    <tr>
                        <td>로스</td>
                        <td>{getTableTd({classType:"LOSS_당일",subulYear:options.thisYear,columnName:"loss_ratio",precision:2})}%</td>
                        <td>{getTableTd({classType:"LOSS_누계",subulYear:options.thisYear,columnName:"loss_ratio",precision:2})}%</td>
                    </tr>
                    </tbody>
            </Table>
        </div>
    );
};

export default ResultMyDaily0912;