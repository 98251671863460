import React, {useContext, useEffect} from 'react';
import {Button, Input} from "reactstrap";
import {ACTION_TYPES, ReducerStateContext,ReducerDispatchContext} from "../context";
import {getYearlySalesAmount0905} from "../api";
import dayjs from "dayjs";
import {useReactToPrint} from "react-to-print";


const SalesReportHeader = ({printRef}) => {

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);

    const handleInputChange = (e,columnName="") =>{
        const options = state.headerOptions
        options[columnName] = e.target.value ?? ""
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"headerOptions",value: options})
    }

    const handleSearchButtonClick = async ()=>{
         const thisYear = Number(dayjs(state.headerOptions.baseDate).format("YYYY"))
        const lastYear = Number(thisYear - 1)
        const apiResult = await getYearlySalesAmount0905({param:"",dataBasket:{thisYear,lastYear}})

        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"mainTable",value: apiResult})
        // console.log("apiResult_0905_1029_",apiResult)
    }

    const onPrint = useReactToPrint({ content: () => printRef.current })

    useEffect(()=>{
        handleSearchButtonClick()
    },[])


    return (
      <div style={{ width: '100%', display:"flex",flexDirection:"row" ,gap:"8px"}}>
        <Input
          type={'date'}
          style={{ width: '150px' }}
          bsSize={'sm'}
          value={state.headerOptions.baseDate}
          onChange={(e) => handleInputChange(e, 'baseDate')}
        />
        <Button size={"sm"} onClick={handleSearchButtonClick}>조회</Button>
        {/*<Button size={"sm"} onClick={onPrint}>인쇄</Button>*/}
      </div>
    )
};

export default SalesReportHeader;