import dayjs from "dayjs";
import {createContext} from "react";


export const INIT_STATE = {
    users: [
    ],
    headerOptions:{
        baseDate:dayjs().format("YYYY-MM-DD"),
        lineOptions : []
    },
    lineInfoTable:[],
    mainTable:[],  // 자사 실적
    myLossRpmTable:[],  // 자사 실적(Loss,RPM)_231004_레포트 추가 요청대응
    subTable:[],  // 외주 실적
    lineNumber:[1,2,3,4,5,6,7,8,9,10]


};


export const ACTION_TYPES = {
    FETCH_START: "CREATE_USER",
    ARRAY_CHANGE: "ARRAY_CHANGE",
    OBJECT_CHANGE: "OBJECT_CHANGE",
};


export const mainReducerActions = (state, action) => {
    switch (action.type) {
        case "REMOVE_USER":
            return {
                ...state,
                users: state.users.filter((user) => user.id !== action.id),
            };

        case "OBJECT_CHANGE":
            const {columnName,name,value} = action
            return {
                ...state,
                [columnName]: {...state[columnName], [name] : value},
            };
        case "ARRAY_CHANGE":
            return {
                ...state,
                [action.columnName]: action.value,
            };

        default:
            throw new Error("Unhandled action");
    }
};


export const ReducerStateContext = createContext({})
export const ReducerDispatchContext = createContext({})


