import React, {useContext, useEffect, useState} from 'react';
import dayjs from "dayjs";
import _ from 'lodash';
import {ACTION_TYPES, ReducerDispatchContext, ReducerStateContext} from "../context";
import AlimSelect from "helpers/AlimSelect";
import {outPlanOutTypeList} from "helpers/OutPlanOutTypeList";
import EachRowSubInfo from "./EachRowSubInfo";
import {Badge, Input} from "reactstrap";
import {nanoid} from "nanoid";
import {userToast} from "helpers/userToast";

const EditEachRow = ({v={}, }) => {
    const dispatch = useContext(ReducerDispatchContext);
    const state = useContext(ReducerStateContext); // 5

    const [eachRow,setEachRow] = useState(v)

    const handleRowClick = () => {
        const {rowId} = v ??""
        console.log("v_241016_1858_",v)
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"selectedRowId",value: rowId})
    }

    const changeValueFNC = ({columnName="",value=""})=>{
        let editedObj = {...eachRow,[columnName]: value,updateYn:1}

        if(columnName === 'partner_company_id'){
            editedObj.product_id = null
            editedObj.product_process_id = null
        }

        setEachRow(editedObj)
        const findIndex = _.findIndex(state.filteredTableList,{rowId:eachRow.rowId})
        if(findIndex > -1) {
            let CL1 = state.filteredTableList
            CL1[findIndex] = editedObj
            dispatch({
                type: ACTION_TYPES.ARRAY_CHANGE,
                columnName: "filteredList",
                value: [...state.filteredTableList, eachRow]
            })
        }
    }

    const inputComponent = ({type="text",columnName="",disabled = false,textAlign="left"})=>{
        const handleInputChange = (e) => {
            changeValueFNC({columnName:columnName,value:e.target.value})
        };
        return <input type={type} value={eachRow[columnName]} style={{textAlign:textAlign,background:"white"}}
                      disabled={disabled}
                      onChange={(e)=>handleInputChange(e)}  />
    }

    const selectOptionComponent = ({columnName="uniqueProduct",valueColumn="product_id"})=>{

        let optionList = []

        if(valueColumn === "product_process_id"){
            optionList = state[columnName].filter((f)=>f.product_id === eachRow.product_id)
        }else{
            optionList = state[columnName]
        }


        const handleEditSelectChange = (e)=>{
            changeValueFNC({columnName:valueColumn,value:e.value})
        }

        if(eachRow.suju_id > 0){
            return <span style={{textAlign:"left"}}>{_.find(optionList, {value : eachRow[valueColumn]})?.label ??""}</span>
        }

        return(
            <AlimSelect
                arrow={false}
                size={'sm'}
                options={optionList}
                value={_.find(optionList, {value : eachRow[valueColumn]}) ?? null}
                onChange={(e) => {
                    handleEditSelectChange(e)
                }}
            />
        )
    }

    const checkBoxComponent = ({type="checkbox",columnName="is_enable"})=>{
        const handleInputChange = (e) => {
            let checked = eachRow[columnName] === 1 ? 0 : 1
            changeValueFNC({columnName:columnName,value:checked})
        };

        return (
            <td rowSpan="2"  style={{background:(eachRow[columnName]=== 1 ? "#0acf97" : "lightpink"), alignContent:"center"}}>
                <input type={type} checked={eachRow[columnName] === 1}
                       style={{width: '20px', height: '20px'}}
                       onChange={(e) => handleInputChange(e)}/>
            </td>
        )
    }

    const handleCopySujuButton = () => {
        const copiedRow = {...eachRow, rowId: nanoid(), suju_id: null,};
        let updatedList = _.cloneDeep([copiedRow,...state.filteredTableList]);
        dispatch({
            type: ACTION_TYPES.ARRAY_CHANGE,
            columnName: "filteredTableList",
            value: updatedList
        });
    }

    const handleDeleteButtonClick = async ()=>{
        const {rowId} = eachRow;
        const updatedList = state.filteredTableList.filter(row => {
            if(row.rowId ===rowId){
                if(row.suju_id > 0){
                    userToast('error','이미 등록된 사항은 삭제가 불가능합니다. [확정] 체크박스를 선택 해제해 주세요.')
                    return row
                }
            }else{
                return row
            }
        });
        dispatch({
            type: ACTION_TYPES.ARRAY_CHANGE,
            columnName: "filteredTableList",
            value: updatedList
        });
    }


    useEffect(() => {
        setEachRow(v)
    }, [v]);



    return (
        <>
            <tr key={`${eachRow.rowId}-44`} onClick={handleRowClick}>
                <td>{(eachRow.suju_id > 0) ? (eachRow.suju_number) :
                    <span style={{fontSize: "0.8rem"}}>신규</span>}</td>
                <td>{selectOptionComponent({columnName: "uniqueProduct", valueColumn: "product_id"})}</td>
                {/*<td rowSpan="2">{inputComponent({type: "text", columnName: "quantity_price", disabled:true})}</td>*/}
                <td rowSpan="2">{inputComponent({type: "text", columnName: "quantity_my",textAlign:"right"})}</td>
                {/*<td rowSpan="2">{inputComponent({type: "text", columnName: "febric_ea", disabled:true})}</td>*/}
                <td>{inputComponent({type: "datetime-local", columnName: "order_date_time"})}</td>
                <td rowSpan="2"> {inputComponent({type: "text", columnName: "remark"})}</td>
                {checkBoxComponent({columnName: "is_enable"})}
                <td rowSpan="2" >
                    <Badge color={"secondary"} onClick={handleCopySujuButton}>복사</Badge>
                    <Badge color={"danger"} onClick={handleDeleteButtonClick}>삭제</Badge>

                </td>
            </tr>
            <tr key={`${eachRow.rowId}-55`} onClick={handleRowClick} style={{borderBottom: "12px solid #dddddd4d"}}>
                <td>{selectOptionComponent({columnName: "partnerList", valueColumn: "partner_company_id"})}</td>
                <td>{selectOptionComponent({columnName: "ppList", valueColumn: "product_process_id"})}</td>
                <td>{dayjs(eachRow.updated_at).format('YYYY-MM-DD HH:mm:ss')}</td>
            </tr>
            <tr>
                <td colSpan={7}>
                    {EachRowSubInfo(eachRow)}
                </td>
            </tr>
        </>
    );
};

export default EditEachRow;