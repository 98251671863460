import React, {useContext, useEffect} from 'react';
import {ACTION_TYPES, ReducerDispatchContext, ReducerStateContext} from "../context";
import {userToast} from "helpers/userToast";
import {getClosingStockDetail0830} from "../api";
import {separateEachTable} from "../function";
import StockDetailTableLeft from "./StockDetailTableLeft";
import StockDetailTableRight from "./StockDetailTableRight";

const StockDetailTable0830 = () => {

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);



    const getDetailInfo = async ()=>{
        const {partner_company_id,year,month}= state.clickedInfo ?? ""
        const apiResult = await getClosingStockDetail0830({param:"",dataBasket:{partner_company_id,year,month}})
        const {detailAllList,detailThisMonth,detailGroupedByPp} = separateEachTable({beforeArray:apiResult,thisMonth:month})
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"detailAllList",value: detailAllList})
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"detailThisMonth",value: detailThisMonth})
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"detailGroupedByPp",value: detailGroupedByPp})
        // console.log("apiResult_08889_",apiResult)
    }

    useEffect(()=>{
        // userToast('','12**3')

        getDetailInfo()


    },[state.clickedInfo])

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          border: '1px solid lightgrey',
          padding: '10px',
        }}
      >
        <StockDetailTableLeft />
        <StockDetailTableRight />
      </div>
    )
};

export default StockDetailTable0830;