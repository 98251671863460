import React, {useEffect, useState} from 'react';
import {userToast} from "helpers/userToast";
import {getDailySalesAmount0911, getDailySujuInfo0911, getSalesPriceCheck0913, getSujuCancelCheck0912} from "../api";
import _ from 'lodash'
import {getRatio0911} from "../function";
import Style from '../style/dashboard.module.scss'
import {Table} from "reactstrap";

import ShowMoreLess0913 from "./ShowMore";


const SalesPriceCheck = ({options={}}) => {

    const [tableList,setTableList] = useState([])
    const [filteredList,setFilteredList] = useState([])
    const [showDetail,setShowDetail] = useState({open:false,count:15})

    const initFnc= async ()=>{
        const apiResult = await getSalesPriceCheck0913({param:"",dataBasket:{...options}})
        console.log("apiResult_0913_0913_",apiResult)
        setTableList(apiResult)
    }

    const handleShowButtonClick = ()=>{
        setShowDetail({...showDetail,open : !showDetail.open})
    }

     useEffect(()=>{
         const { open,count} = showDetail
         if(!open) setFilteredList(tableList.slice(0,count))
         if(open) setFilteredList(tableList)
    },[showDetail,tableList])

    useEffect(()=>{
        if(options.thisYear > 0){
            initFnc()
        }
    },[options])



    return (
        <>
            {tableList.length > 0 ? (
                <div className={`${Style.wrapper}`} style={{width:"100%"}}>
                    <span className={`${Style.subTitle}`}>영업단가 미등록 내역(수주 최근 1개월) / 총 {tableList.length}건</span>
                    <Table bordered={true}  size={"sm"}>
                        <colgroup>
                            <col width={'1%'} />
                            <col width={'1%'} />
                            <col width={'6%'} />
                            <col width={'1%'} />
                            <col width={'2%'} />
                            <col width={'5%'} />
                        </colgroup>
                        <thead>
                        <tr>
                            <th>No</th>
                            <th>구분</th>
                            <th>제품</th>
                            <th>담당</th>
                            <th>수주</th>
                            <th>비고</th>
                        </tr>

                        </thead>
                        <tbody>
                        {(filteredList.length >0) && filteredList.map((v,i)=>{
                            return (
                                <tr>
                                    <td>{i+1}</td>
                                    <td>{v.export_yn === 0  ?"내수" : "수출"}</td>
                                    <td style={{textAlign:"left"}}>{v.product_name}</td>
                                    <td>{v.my_manager_name}</td>
                                    <td>{v.suju_number}</td>
                                    <td style={{fontSize:"0.8rem"}}>{v.remark ?? ""}{v.label_note ?? ""}</td>
                                </tr>
                            )
                        })}

                        </tbody>
                    </Table>
                    <span className={`${Style.bottomComment}`} style={{marginBottom:"15px"}}>(※ 최근 수주정보가 등록이 된 대상 중, 아직 "판매 단가"가 등록되지 않은 대상들입니다.)</span>
                    <ShowMoreLess0913 show={showDetail.open} onClick={()=>handleShowButtonClick()}/>
                </div>
            ) : null}
        </>
    );
};

export default SalesPriceCheck;