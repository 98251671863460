import React from 'react';
import Header241020 from "helpers/outsourcing/outsourcedProcessSales/component/Header";
import BodyTableSalesResult241020 from "helpers/outsourcing/outsourcedProcessSales/component/BodyTable";

const ResultInTable241023 = () => {
    return (
        <div>
            <Header241020/>
            <BodyTableSalesResult241020 />
        </div>
    );
};

export default ResultInTable241023;