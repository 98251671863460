import React, {useContext} from 'react';
import {getTableTdData, getTdBackgroundColor1102} from "../function";
import _ from "lodash";
import Style from "../style/table.module.scss";
import {ACTION_TYPES, ReducerDispatchContext, ReducerStateContext} from "../context";
import dayjs from "dayjs";

const ClickTableTd = ({targetYear,targetMonth,partnerCompanyId}) => {
    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);

    const getTableTdData= ({columnName=""})=>{
        const groupedList = _.cloneDeep(state.groupedByPartner)
        const tempArray = groupedList[partnerCompanyId] ?? []
        if(tempArray.length > 0 ){
            const filteredList = tempArray.filter((f)=>f.subul_year === targetYear && f.subul_month === targetMonth)
            const subTotal = _.ceil(_.sumBy(filteredList,columnName)/1) ?? 0
            return subTotal
        }else{
            return 0
        }
    }

    const getRatioFnc = (top=1,bottom=1)=>{
        if(top > 0 && bottom > 0) return _.ceil(top/bottom,1)
        return null
    }


    const handleTdClick = ()=>{
        const tempObj = {...state.clickedInfo,year:targetYear,month:targetMonth,partner_company_id:partnerCompanyId}
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"clickedInfo",value: tempObj})
    }


    const top = getTableTdData({ columnName: 'now_amount' })
    const bottom = getTableTdData({ columnName: 'out_amount' })


    return (
      <>
        <td onClick={handleTdClick}>
          {getTableTdData({ columnName: 'out_amount' }).toLocaleString()}
        </td>
          <td onClick={handleTdClick}>
          {getTableTdData({ columnName: 'now_amount' }).toLocaleString()}
        </td>
        <td onClick={handleTdClick} className={`${Style.stockMonthlyInfo}`} style={{backgroundColor:getTdBackgroundColor1102({top:top,bottom:bottom})}} >
          {getRatioFnc(getTableTdData({ columnName: 'now_amount' }), getTableTdData({ columnName: 'out_amount' }))}
        </td>
      </>
    )
};

export default ClickTableTd;