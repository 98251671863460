import React, {useEffect, useState} from 'react';
import {userToast} from "helpers/userToast";
import {getDailySalesAmount0911} from "../api";
import _ from 'lodash'
import {getRatio0911} from "../function";
import Style from '../style/dashboard.module.scss'
import {Table} from "reactstrap";

const SalesDailyInfo0911 = ({options={}}) => {

    const [tableList,setTableList] = useState([])

    const initFnc= async ()=>{
        const apiResult = await getDailySalesAmount0911({param:"",dataBasket:{...options}})
        console.log("apiResult_09111507_",apiResult)
        setTableList(apiResult)
    }

    const getTableTd = ({year,mainType="당일"})=>{
        if(!tableList.length > 0) return 0
        const CL1 = _.cloneDeep(tableList)
        const CL2 = CL1.filter((f)=> f.subul_year === year && f.main_type === mainType)

        if(CL2.length > 0){
            return _.floor(CL2[0]?.out_amount,0) ?? 0
        }else{
            return 0
        }


    }

    useEffect(()=>{
        if(options.thisYear > 0){
            initFnc()
        }
    },[options])


    return (
        <div className={`${Style.wrapper}`}>
            <span className={`${Style.subTitle}`}>일일 매출 현황</span>
            <Table bordered={true}  size={"sm"}>
                <colgroup>
                    <col width={'2%'} />
                    <col width={'8%'} />
                    <col width={'2%'} />
                    <col width={'8%'} />
                </colgroup>
                <thead>
                <tr>
                    <th colSpan="2">당 일</th>
                    <th colSpan="2">누 계</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={`${Style.categoryBg}`}>계획</td>
                    <td>{getTableTd({year:options.lastYear,mainType:"당일"}).toLocaleString()}</td>
                    <td className={`${Style.categoryBg}`}>전년</td>
                    <td>{getTableTd({year:options.lastYear,mainType:"누계"}).toLocaleString()}</td>
                </tr>
                <tr>
                    <td className={`${Style.categoryBg}`}>실적</td>
                    <td>{getTableTd({year:options.thisYear,mainType:"당일"}).toLocaleString()}</td>
                    <td className={`${Style.categoryBg}`}>당해</td>
                    <td>{getTableTd({year:options.thisYear,mainType:"누계"}).toLocaleString()}</td>
                </tr>
                <tr>
                    <td className={`${Style.categoryBg}`}>달성률</td>
                    <td>{
                        getRatio0911({
                            top:getTableTd({year:options.thisYear,mainType:"당일"}),
                            bottom:getTableTd({year:options.lastYear,mainType:"당일"}),
                        })
                    }%</td>
                    <td className={`${Style.categoryBg}`}>달성률</td>
                    <td>{
                        getRatio0911({
                            top:getTableTd({year:options.thisYear,mainType:"누계"}),
                            bottom:getTableTd({year:options.lastYear,mainType:"누계"}),
                        })
                    }%</td>
                </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default SalesDailyInfo0911;