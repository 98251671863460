import React from 'react';
import Style from '../style/table.module.scss'
import YearlyTableRow from "./YearlyTableRow";

const YearlyTable = () => {



    return (
      <div className={`${Style.salesTable}`} style={{ marginTop: '0.5rem',padding:"10px" }}>
        <div style={{display:"flex",flexDirection:"row",justifyContent:"flex-end"}}>
          <span>(단위 : 백만원)</span>
        </div>
        <table>
          <colgroup>
            <col width={'6%'} />
            <col width={'3%'} />

            <col width={'3%'} />
            <col width={'3%'} />
            <col width={'3%'} />
            <col width={'3%'} />
            <col width={'3%'} />
            <col width={'3%'} />
            <col width={'3%'} />
            <col width={'3%'} />
            <col width={'3%'} />
            <col width={'3%'} />
            <col width={'3%'} />
            <col width={'3%'} />
            <col width={'3%'} />
          </colgroup>
          <thead>
            <tr>
              <th colSpan="2">구분</th>
              <th>누계</th>
              <th>1월</th>
              <th>2월</th>
              <th>3월</th>
              <th>4월</th>
              <th>5월</th>
              <th>6월</th>
              <th>7월</th>
              <th>8월</th>
              <th>9월</th>
              <th>10월</th>
              <th>11월</th>
              <th>12월</th>
            </tr>
          </thead>
          <tbody>
            <YearlyTableRow label={'내수'} />
            <YearlyTableRow label={'수출'} />
            <YearlyTableRow label={'합계'} />
          </tbody>
        </table>
      </div>
    )
};

export default YearlyTable;