import React, {useContext, useEffect, useState} from 'react';
import {ACTION_TYPES, ReducerDispatchContext, ReducerStateContext} from "../context";
import {Table} from "reactstrap";
import Style from '../style/table.module.scss'
import _ from 'lodash'
import {nanoid} from "nanoid";
import TableUnitInfo from "../../../../../../../components/closingCost/TableUnitInfo";

const StockDetailTableLeft = () => {

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);

    const [tableList,setTableList] = useState([])

    const handleTrClick = (v)=>{
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"detailSelectedInfo",value: v})
    }

    useEffect(()=>{
        const CL1 = _.sortBy(state.detailThisMonth,"now_amount").reverse()
        const CL2 = CL1.map((v)=> {
            return {...v,rowId:nanoid()}
        })
        setTableList(CL2)
    },[state.detailThisMonth])


    return (
        <div style={{height:"20vh",overflow:"auto",flex:1}} className={`${Style.detailMonthlyTable}`}>
            <h3>{tableList[0]?.partner_company_name ??""}</h3>
            {/*<p>{JSON.stringify(state.detailSelectedInfo)}</p>*/}
            <TableUnitInfo label={"(단위 : 원, 원, 개월/월)"} />
            <table size={"sm"}>
                <thead>
                    <tr>
                        <th>제품명</th>
                        <th>재고금액</th>
                        <th>판매금액</th>
                        <th>재고월수</th>
                    </tr>
                </thead>
                <tbody>
                {state.detailThisMonth && tableList.map((v)=>{
                    return (
                        <tr onClick={()=>handleTrClick(v)} key={v.rowId} style={{backgroundColor:(v.rowId === state?.detailSelectedInfo?.rowId ?? "") ? "lightgrey" : ""}}>
                            <td style={{textAlign:"left"}}>{v.semi_product_name}</td>
                            <td>{_.ceil(v.now_amount,0).toLocaleString() ?? ""}</td>
                            <td>{_.ceil(v.out_amount,0).toLocaleString() ?? ""}</td>
                            <td>{_.floor(v.amount_per_monthly,1) ?? ""}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
};

export default StockDetailTableLeft;