import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const ShowMoreLess0913 = ({show=false,onClick}) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          width: '100%',
          border: '1px solid lightGrey',
          gap: '5px',
        }}
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={show ? solid('caret-up') : solid('caret-down')}
          size={'2x'}
          color={'#c9c9c9'}
          style={{ cursor: 'pointer', marginTop: '0.1rem' }}
        />
        <span>{show ? '최근 15건 조회' : '전체 조회'}</span>
      </div>
    )
};

export default ShowMoreLess0913;