import React, {useContext, useEffect, useState} from 'react';
import {ACTION_TYPES, ReducerStateContext,ReducerDispatchContext} from "../context";
import Style from '../style/table.module.scss'
import dayjs from "dayjs";
import _ from 'lodash'
import {userToast} from "helpers/userToast";
import ClickTableTd from "./ClickTableTd";
import AlimPaginationComponent from "../../../../../../../components/AlimPaginationComponent";
import {getTableTdSubtotal} from "../function";
import SubtotalTrComponent from "./SubtotalTrComponent";

const MonthlyStockTable0830 = ({tableRef}) => {

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);
    const thisYear = Number(dayjs(state.headerOptions.startDate).format('YYYY'))
    const lastYear = thisYear - 1

    const [pagination,setPagination] = useState({perPage:20,nowPage:1})
    const {perPage,nowPage} = pagination


    const getRatioFnc = (top=1,bottom=1)=>{
        if(top > 0 && bottom > 0) return _.ceil(top/bottom,1)
        return null
    }


    const subTotalTdComponents= ({labelName="",targetYear="",partnerId=0})=>{

        return(
            <>
                <td  className={`${Style.textCenter}`}>{labelName}</td>
                <td>{getTableTdSubtotal({baseList:state.groupedByPartner,columnName:"out_amount",partnerCompanyId:partnerId,targetYear:targetYear}).toLocaleString()}</td>
                <td>{getTableTdSubtotal({baseList:state.groupedByPartner,columnName:"now_amount",partnerCompanyId:partnerId,targetYear:targetYear}).toLocaleString()}</td>
                <td   className={`${Style.stockMonthlyInfo}`}>
                    {getRatioFnc(
                        getTableTdSubtotal({baseList:state.groupedByPartner,columnName:"now_amount",partnerCompanyId:partnerId,targetYear:targetYear}),
                        getTableTdSubtotal({baseList:state.groupedByPartner,columnName:"out_amount",partnerCompanyId:partnerId,targetYear:targetYear})
                    )}
                </td>
            </>
        )
    }

    return (
      <div>
          <div  className={`${Style.monthlyStockTable}`}>
              <table ref={tableRef}>
                  <thead>
                  <tr>
                      <th rowSpan={2} className={`${Style.tableSticky}`}>
                          구분
                      </th>
                      <th rowSpan={2} style={{ width: '50px' }}>
                          분류
                      </th>
                      <th colSpan={3}>합계</th>
                      {state.monthList.map((v) => {
                          return <th colSpan={3}>{v}월</th>
                      })}
                  </tr>
                  <tr>
                      <th>매출액</th>
                      <th>재고금액</th>
                      <th>재고월수</th>
                      {state.monthList.map((v) => {
                          return (
                              <>
                                  <th>매출액</th>
                                  <th>재고금액</th>
                                  <th>재고월수</th>
                              </>
                          )
                      })}
                  </tr>
                  </thead>
                  <tbody>

                  <SubtotalTrComponent thisYear={thisYear} lastYear={lastYear} />
                  {state.uniqByPartner && state.uniqByPartner.slice((nowPage-1)* perPage,nowPage *perPage).map((vv)=>{
                      return (
                          <>
                              <tr>
                                  <td className={`${Style.tableSticky}`} style={{textAlign:"left"}} rowSpan={2}>
                                      {vv.partner_company_name}
                                  </td>
                                  {subTotalTdComponents({labelName:"전년",targetYear:lastYear,partnerId:vv.partner_company_id})}
                                  {state.monthList.map((v) => {
                                      return (
                                          <ClickTableTd partnerCompanyId={vv.partner_company_id} targetYear={lastYear} targetMonth={v} />
                                      )
                                  })}
                              </tr>
                              <tr>
                                  {subTotalTdComponents({labelName:"올해",targetYear:thisYear,partnerId:vv.partner_company_id})}
                                  {state.monthList.map((v) => {
                                      return (
                                          <ClickTableTd partnerCompanyId={vv.partner_company_id} targetYear={thisYear} targetMonth={v} />
                                      )
                                  })}

                              </tr>
                          </>
                      )
                  })}


                  </tbody>
              </table>
          </div>
          <AlimPaginationComponent pagination={pagination} setPagination={setPagination} tableList= {state.uniqByPartner} selectOptions={[20,100,200,500]} />
      </div>
    )
};

export default MonthlyStockTable0830;