import dayjs from "dayjs";
import {createContext} from "react";


export const INIT_STATE = {
    users: [
    ],
    headerOptions:{
        startDate:dayjs().format("YYYY-MM-DD"),
    },
    mainTable:[],
    groupedByPartner:{}, // 거래처 중심으로 데이터 모아둔 곳!
    uniqByPartner:[], // 거래처 중심 목록
    monthList : [1,2,3,4,5,6,7,8,9,10,11,12],
    clickedInfo:{partner_company_id:0,year:0,month:0},

    detailAllList:[],  // 전체 리스트
    detailThisMonth:[], // 선택한 당월에 대한 내용
    detailGroupedByPp:[],  // 선택한 제품에 대한 정보만 추출
    detailSelectedInfo:{},  // 선택한 제품에 대한 정보만 추출


};


export const ACTION_TYPES = {
    FETCH_START: "CREATE_USER",
    ARRAY_CHANGE: "ARRAY_CHANGE",
    OBJECT_CHANGE: "OBJECT_CHANGE",
};


export const mainReducerActions = (state, action) => {
    switch (action.type) {
        case "REMOVE_USER":
            return {
                ...state,
                users: state.users.filter((user) => user.id !== action.id),
            };

        case "OBJECT_CHANGE":
            const {columnName,name,value} = action
            return {
                ...state,
                [columnName]: {...state[columnName], [name] : value},
            };
        case "ARRAY_CHANGE":
            return {
                ...state,
                [action.columnName]: action.value,
            };

        default:
            throw new Error("Unhandled action");
    }
};


export const ReducerStateContext = createContext({})
export const ReducerDispatchContext = createContext({})


