import React, {useContext, useEffect, useReducer, useState} from 'react';
import Style from "../style/table.module.scss";
import {ACTION_TYPES, ReducerStateContext,ReducerDispatchContext} from "../context";
import dayjs from "dayjs";
import _ from 'lodash'
import { userToast } from 'helpers/userToast'
import {eachTableTdAmount, eachTableTdRatio, eachTableTdSubTotal} from "../function";

const YearlyTableRow = ({ label = '수출' }) => {
  const state = useContext(ReducerStateContext) // 5
  const dispatch = useContext(ReducerDispatchContext)
  const [tableList, setTableList] = useState([])
  const monthly = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  const thisYear = Number(dayjs(state.headerOptions.baseDate).format("YYYY"))
  const lastYear = Number(thisYear - 1)

  useEffect(() => {
    if (state.mainTable.length > 0) {

      const CL =
        _.cloneDeep(state.mainTable).filter((f) => {
          if (label === '합계') return f
          if (label === '수출' && f.export_yn === 1) return f
          if (label === '내수' && f.export_yn === 0) return f
        }) ?? []

        // console.log(`${label}==_.cloneDeep(state.mainTable)_0905188_=`, CL)
      setTableList(CL)
    }
  }, [state.mainTable,label])




  return (
    <>
      <tr >
        <td rowSpan="3" className={`${Style.category}`}>
          {label}
        </td>
        <td className={`${Style.category}`}>전년</td>
        <td>{eachTableTdSubTotal({ tableList,year: lastYear }).toLocaleString()}</td>
        {monthly.map((v) => {
          return <td>{eachTableTdAmount({tableList, year: lastYear, month: v }).toLocaleString()}</td>
        })}
      </tr>
      <tr>
        <td className={`${Style.category}`}>올해</td>
        <td>{eachTableTdSubTotal({ tableList,year: thisYear }).toLocaleString()}</td>
        {monthly.map((v) => {
          return <td>{eachTableTdAmount({ tableList,year: thisYear, month: v }).toLocaleString()}</td>
        })}
      </tr>
      <tr className={`${Style.borderBottom}`} >
        <td className={`${Style.category}`}>증감(%)</td>
        <>
          {eachTableTdRatio({
            top: eachTableTdSubTotal({ tableList,year: thisYear }),
            bottom: eachTableTdSubTotal({tableList, year: lastYear }),
          })}
        </>
        {monthly.map((v) => {
          return <>
              {eachTableTdRatio({
                  top: eachTableTdAmount({ tableList,year: thisYear, month: v }),
                  bottom:eachTableTdAmount({tableList, year: lastYear, month: v }),
              })}
          </>
        })}
      </tr>
    </>
  )
}

export default YearlyTableRow;