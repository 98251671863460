import React, {useReducer} from 'react';
import ResultTable0907 from "./components/ResultTable";
import ResultHeader0907 from "./components/ResultHeader";
import {ACTION_TYPES,INIT_STATE, ReducerStateContext, ReducerDispatchContext, mainReducerActions} from "./context";

const ResultLength0907 = () => {
    const [state,dispatch] = useReducer(mainReducerActions,INIT_STATE )


    return (
        <ReducerStateContext.Provider value = {state}>
            <ReducerDispatchContext.Provider value = {dispatch}>
                <ResultHeader0907 />
                <ResultTable0907/>
            </ReducerDispatchContext.Provider>
        </ReducerStateContext.Provider>


    );
};

export default ResultLength0907;