import crypto from 'crypto'

const cryptoKey = process.env.REACT_APP_CRYPTO_KEY
const cryptoIv = process.env.REACT_APP_CRYPTO_IV
const algorithm = process.env.REACT_APP_CRYPTO_ALGORITHM

export const encryptFunction = (textValue) => {
  let editedText = textValue
  if (typeof textValue === 'number') {
    editedText = textValue.toString()
  }
  const cipher = crypto.createCipheriv(algorithm, cryptoKey, cryptoIv) //key는 32바이트, iv는 16바이트
  let result = cipher.update(editedText, 'utf8', 'base64')
  result += cipher.final('base64')
  console.log('암호화(Helpers): ', result)
  return result
}

export const decryptFunction = (textValue) => {
  let editedText2 = textValue
  if (typeof textValue === 'number') {
    editedText2 = textValue.toString()
  }
  const deciper = crypto.createDecipheriv(algorithm, cryptoKey, cryptoIv) //key는 32바이트, iv는 16바이트
  let result = deciper.update(editedText2, 'base64', 'utf8')
  result += deciper.final('utf8')
  // console.log('복호화(Helpers): ', result)
  return result
}
