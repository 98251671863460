import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import React from 'react'
import _ from 'lodash'
/**
 * @callback onChangeInput
 * @param {{value: any, label: any}} data
 * */
/**
 * @callback promiseOptionFunc
 * @return Promise<Array<{value: any, label: any}>>
 * */
/**
 * @param {any} value
 * @param {onChangeInput} onChange
 * @param {Array<{value: any, label: any}>} options
 * @param {promiseOptionFunc} promiseOptions
 * @param {boolean} async
 * @param rest
 * */
const AlimSelect = ({
  style,
  size = false,
  styles,
  value,
  isDisabled,
  maxOptions = 100,
  name,
  id,
  onChange,
  async = false,
  options,
  promiseOptions,
  arrow = true,
  menuPosition = "fixed",
    placeholder="Search...",
  isClearable,
  onBlur,
  readOnly = false,
  className,
  controlHeight = false,
  baseUnit = 2,
  borderYn = 1,
  ...rest
  // simpleOptions,
}) => {
  if (async) {
    return (
      <AsyncSelect
        value={value}
        onChange={onChange}
        defaultOptions={options}
        loadOptions={promiseOptions()}
        theme={(theme) => ({
          ...theme,
          spacing: {
            ...theme.spacing,
            controlHeight: 32,
            baseUnit: 2,
          },
        })}
        {...rest}
      />
    )
  }
  const MenuList = ({ children, ...props }) => {
    return (
      <components.MenuList {...props}>
        {
          Array.isArray(children)
            ? children.slice(0, props.selectProps?.maxOptions) // Options
            : children // NoOptionsLabel
        }
      </components.MenuList>
    )
  }

  const DropdownIndicator = ({ children, ...props }) => {
    return arrow ? <components.DropdownIndicator {...props}>{null}</components.DropdownIndicator> : null
  }
  const IndicatorSeparator = ({ children, ...props }) => {
    return arrow ? <components.IndicatorSeparator {...props}>{null}</components.IndicatorSeparator> : null
  }

  const getStyleObj = () => {
    let afterObj = _.cloneDeep(style)
    const borderNone = {
      control: (provided) => ({
        ...provided,
        border: '0px solid white',
        backgroundColor: 'white',
        outline: 'none',
        marginLeft: '5px',
      }),
    }
    if (borderYn === 0) {
      afterObj = { ...afterObj, ...borderNone }
    }
    return afterObj
  }

  const sizeFnc = (theme) => {
    if (size === 'sm') {
      return {
        ...theme,
        spacing: {
          ...theme.spacing,
          controlHeight: 32,
          baseUnit: baseUnit,
        },
      }
    }
    if (size === 'xs') {
      return {
        ...theme,
        spacing: {
          ...theme.spacing,
          controlHeight: 14,
          baseUnit: baseUnit,
        },
      }
    }
  }

  const getThemeObj = (theme) => {
    let beforeObj = {}
    let afterObj = null

    beforeObj = {
      ...theme,
      spacing: {
        ...theme.spacing,
        controlHeight: borderYn === 0 ? 0 : controlHeight,
        baseUnit: borderYn === 0 ? 0 : baseUnit,
      },
    }
    afterObj = { ...afterObj, ...beforeObj }
    return afterObj
  }

  return (
    <>
      <Select
        onBlur={onBlur}
        isDisabled={isDisabled}
        value={value}
        onChange={onChange}
        options={options}
        isClearable={isClearable}
        size={size}
        name={name}
        className={className}
        id={id}
        theme={
          size
            ? (theme) => sizeFnc(theme)
            : size === false && controlHeight === false
            ? null
            : (theme) => getThemeObj(theme)
        }
        components={{ MenuList, DropdownIndicator, IndicatorSeparator }}
        clsoeMenuOnSelect={arrow}
        maxOptions={maxOptions}
        placeholder ={placeholder}
        styles={getStyleObj()}
        menuPosition={menuPosition}
      />
    </>
  )
}
export default AlimSelect

export const selectValueFind = (options, value) => {
  return _.find(options, { value: value }) ?? ''
}
