import React, {useEffect, useState} from 'react';
import {userToast} from "helpers/userToast";
import {
    getDailySalesAmount0911,
    getDailySujuInfo0911,
    getSujuCancelCheck0912,
    getSujuCancelMaterialOrderCheck0915
} from "../api";
import _ from 'lodash'
import {getRatio0911} from "../function";
import Style from '../style/dashboard.module.scss'
import {Table} from "reactstrap";
import dayjs from "dayjs";

const SujuCancelMaterial0912 = ({options={}}) => {

    const [tableList,setTableList] = useState([])

    const initFnc= async ()=>{
        const apiResult = await getSujuCancelMaterialOrderCheck0915({param:"",dataBasket:{...options}})
        console.log("apiResult_0915_0951_",apiResult)
        setTableList(apiResult)
    }

    const getTableTd = ({classType="",productYn=0,columnName=""})=>{
        if(!tableList.length > 0) return 0
        const CL1 = _.cloneDeep(tableList)
        const CL2 = CL1.filter((f)=> f.product_yn === productYn && f.class_type === classType)

        if(CL2.length > 0){
            return _.floor(CL2[0][columnName],0) ?? 0
        }else{
            return 0
        }
    }
    const getTableSubtotalTd = ({classType="",columnName=""})=>{
        if(!tableList.length > 0) return 0
        const CL1 = _.cloneDeep(tableList)
        const CL2 = CL1.filter((f)=> f.class_type === classType)

        if(CL2.length > 0){
            const tempValue = _.sumBy(CL2,columnName) ?? 0
            return _.floor(tempValue,0) ?? 0
        }else{
            return 0
        }
    }

    useEffect(()=>{
        if(options.thisYear > 0){
            initFnc()
        }
    },[options])




    return (
       <>
           {tableList.length > 0 ? (
               <div className={`${Style.wrapper}`} style={{width:"100%"}}>
                   <span className={`${Style.subTitle}`}>자재발주내역(수주취소 내역 점검)_최근 1개월</span>
                   <Table bordered={true}  size={"sm"}>
                       <colgroup>
                           <col width={'1%'} />
                           <col width={'2%'} />
                           <col width={'2%'} />
                           <col width={'5%'} />
                           <col width={'1%'} />
                           <col width={'2%'} />
                           <col width={'2%'} />
                           <col width={'1%'} />
                       </colgroup>
                       <thead>
                       <tr>
                           <th>No</th>
                           <th>수주</th>
                           <th>발주일</th>
                           <th>자재명</th>
                           <th>발주량</th>
                           <th>발주처</th>
                           <th>입고처</th>
                           <th>실입고건수</th>
                       </tr>

                       </thead>
                       <tbody>
                       {(tableList.length >0) && tableList.map((v,i)=>{
                           return (
                               <tr>
                                   <td>{i+1}</td>
                                   <td>{v.suju_number}</td>
                                   <td>{dayjs(v.material_order_date).format("YYYY-MM-DD")}</td>
                                   <td style={{textAlign:"left"}}>{v.material_name}</td>
                                   <td>{v.subul_quantity}</td>
                                   <td>{v.order_company_name}</td>
                                   <td>{v.in_company_name}</td>
                                   <td style={{backgroundColor : (v.in_count === 0 ? "" : "lightpink")}}>{v.in_count}</td>
                               </tr>
                           )
                       })}

                       </tbody>
                   </Table>
                   <span className={`${Style.bottomComment}`}>(※수주정보가 취소 이전에 자재발주가 등록된 경우입니다. '발주정보 및 입고실적'의 재점검 필요합니다.)</span>
               </div>
           ) : null}
       </>
    );
};

export default SujuCancelMaterial0912;