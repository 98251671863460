import React, {useReducer, useState} from 'react';
import {ACTION_TYPES,INIT_STATE, ReducerStateContext, ReducerDispatchContext, mainReducerActions} from "./context";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header241020 from "helpers/outsourcing/outsourcedProcessSales/component/Header";
import BodyTableSalesResult241020 from "helpers/outsourcing/outsourcedProcessSales/component/BodyTable";
import ResultInTable241023 from "helpers/outsourcing/outsourcedProcessSales/component/ResultInTable";
import SalesOutTable1025 from "helpers/outsourcing/outsourcedProcessSales/component/SalesOutTable";
import RecentCostPrice241028 from "helpers/outsourcing/outsourcedProcessSales/component/RecentCostPrice";


const OutsourcedProcessSales241020 = () => {

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [state,dispatch] = useReducer(mainReducerActions,INIT_STATE )

    // 액션
    // dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"mainTable",value: apiResult})


    return (
        <div>
            <ReducerStateContext.Provider value = {state}>
                <ReducerDispatchContext.Provider value = {dispatch}>
                    <Button color="secondary" outline={true} size={"sm"} onClick={toggle}>
                        외주 정산관리
                    </Button>
                    <Modal isOpen={modal} toggle={toggle}  backdrop={true} style={{minWidth:"95%"}}>
                        <ModalHeader toggle={toggle}>외주 매각현황</ModalHeader>
                        <ModalBody style={{minHeight:"85vh", overflow:"auto"}}>


                            <ResultInTable241023/>

                            <div style={{height:"35vh",overflow:"auto",display:"flex",flexDirection:"row",gap:"0.4rem"}}>
                                <SalesOutTable1025 /> {/*생산실적 중, 신규등록할 대상*/}
                                <RecentCostPrice241028/>

                            </div>


                        </ModalBody>
                        <ModalFooter>
                            <Button size={"sm"}  color="secondary" onClick={toggle}>
                                닫기
                            </Button>
                        </ModalFooter>
                    </Modal>
                </ReducerDispatchContext.Provider>
            </ReducerStateContext.Provider>

        </div>
    );
};

export default OutsourcedProcessSales241020;