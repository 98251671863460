import React, {useEffect, useState} from 'react';
import {Input, Pagination, PaginationItem, PaginationLink} from 'reactstrap'
import _ from "lodash";


const AlimPaginationComponent = ({pagination,setPagination,tableList,selectOptions=[100,200,500] }) => {

    const {perPage,nowPage} = pagination
    const [tableLength,setTableLength] = useState(0)

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setPagination({...pagination,"perPage":value,"nowPage":1})
    }


    useEffect(()=>{
        setTableLength(Math.ceil(tableList.length/perPage))
    },[tableList,perPage])

    return (
        <>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-end"}}>
                <div style={{marginTop:"0.5rem", display:"flex", justifyContent:"flex-start"}}>
                    <Input
                        placeholder="부서명"
                        size={'sm'}
                        type="select"
                        style={{width:"100px", height:"36px"}}
                        onChange={(e) => handleInputChange(e)}
                    >
                        {selectOptions && selectOptions.map((v,i)=>{
                            return (
                                <option value={v} selected={perPage === v} key={`${i}_11505`}>{v}</option>
                            )
                        })}
                    </Input>
                    <Pagination aria-label="Page navigation example">
                        <PaginationItem>
                            <PaginationLink
                                first
                                onClick={() => setPagination({...pagination, "nowPage": 1})}
                            />
                        </PaginationItem>
                        <PaginationItem disabled={nowPage === 1}>
                            <PaginationLink
                                previous={true}
                                onClick={() => setPagination({...pagination, "nowPage": nowPage - 1})}
                            />
                        </PaginationItem>
                        {[...Array(tableLength)].map((v, i) => {
                            if ((i + 1 >= nowPage - 2 && i + 1 <= nowPage) || (i + 1 <= nowPage + 2 && i + 1 >= nowPage)) {
                                return (
                                    <PaginationItem active={i + 1 === nowPage}
                                                    onClick={() => setPagination({...pagination, nowPage: i + 1})}
                                    >
                                        <PaginationLink>
                                            {i + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                )
                            }

                        })}
                        <PaginationItem disabled={nowPage === tableLength}>
                            <PaginationLink
                                next
                                onClick={() => setPagination({...pagination, "nowPage": nowPage + 1})}
                            />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink
                                href="#"
                                last
                                onClick={() => setPagination({...pagination, "nowPage": tableLength})}
                            />
                        </PaginationItem>
                    </Pagination>
                </div>
            </div>
        </>
    );
};

export default AlimPaginationComponent;