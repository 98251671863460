import axios from 'axios'
import {getUserId, getUserPartnerCompanyId2, getUserType, userCompany} from 'helpers/getUserInfo'
import dayjs from 'dayjs'
import {userToast} from "helpers/userToast";
import _ from 'lodash'


const BASE_URL = '/apps/businessManagement/monthlyClosing/currentKpi'




export const getClosingStock0830 = async ({ param = '', dataBasket = {} }) => {
  const companyCode = userCompany()
  const userId = getUserId()
  const userType = getUserType()
  const userTypeCPartnerCompanyId = getUserPartnerCompanyId2()

  const params = {
    companyCode,
    userId,
    param,
    dataBasket,
    userType,
    userTypeCPartnerCompanyId,
  }

  const vv = await axios.post(`${BASE_URL}/getClosingStock0830`, {
    params,
  })

  if (vv.data.success) {
    const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
    return filteredArray
  }
}



export const getClosingStockDetail0830 = async ({ param = '', dataBasket = {} }) => {
  const companyCode = userCompany()
  const userId = getUserId()
  const userType = getUserType()
  const userTypeCPartnerCompanyId = getUserPartnerCompanyId2()

  const params = {
    companyCode,
    userId,
    param,
    dataBasket,
    userType,
    userTypeCPartnerCompanyId,
  }

  const vv = await axios.post(`${BASE_URL}/getClosingStockDetail0830`, {
    params,
  })

  if (vv?.data?.success) {
    const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
    return filteredArray
  }
}
