import axios from 'axios'
import { getUserCompany } from 'helpers/getUserInfo'
import _ from 'lodash'
import { userToast } from 'helpers/userToast'
import dayjs from "dayjs";

export const getMaxConfirm = async () => {
  const company_code = getUserCompany()
  let body = { company_code }
  return await axios.post(`/apps/helpers/getMaxConfirm`, body).then((res) => {
    if (res.data?.success === true) {
      let data = res.data.data
      // console.log('getMaxConfirm@@@', data)
      return data[0]
    }
  })
}

export const getSearchOptionByMaxConfirm240904 = async ()=>{
  // 시작일 : 원가 마감이 최종적으로 마무리 된 이후 첫날 (24.07.31마감 -> 24.08.01)
  // 종료일 : 오늘

  let data = await getMaxConfirm()
  const {account_year,account_month,confirm_year_month} = data ?? ""
  const baseStr = `${confirm_year_month}-01`
  const startDate= dayjs(baseStr).add(1,'month').format('YYYY-MM-DD')
  const lastDate = dayjs().format('YYYY-MM-DD')

  return {startDate,lastDate}
}

export const deadlineConfirm = async (dataArray, dateKey, alertState = true) => {
  let checkArray = _.cloneDeep(dataArray)
  let data = await getMaxConfirm()
  let confirm_year_month = data?.confirm_year_month || '0000-00'
  let result = false
  for (let key in checkArray) {
    let curObj = checkArray[key]?.[dateKey]
    let checkDate = curObj?.split('-')
    if (confirm_year_month >= `${checkDate[0]}-${checkDate[1]}`) {
      if (alertState) alert('마감 확정되었습니다. 확정일 이후 정보를 입력하세요.')
      return true
    }
  }
  return result
}

/** Example *********************************************************

 deadlineConfirm([{ inDay: value }], 'inDay', false).then((res) => {
      setIsDead(res)
      deadLineToast(res, '조회/인쇄만 가능합니다.')
    })

 ********************************************************* */





/** test 용도
 *
 * month
 *
 */
export const testMonth = (target, testMonth) => {
  const test = '2022-' + testMonth

  const split = target.split('-')
  console.log(test >= split[0] + '-' + split[1])
  return test >= split[0] + '-' + split[1]
}

//

/**
 * @return true: Toastify, false: skip
 *
 */
export const deadLineToast = (isDead, addText) => {
  if (isDead) {
    userToast('warning', `마감 확정된 기간입니다. ${addText} `, 2)
  }
}
