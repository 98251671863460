import React, {useEffect} from 'react';
import {getResultList241025, saveSalesOut241025} from "helpers/outsourcing/outsourcedProcessSales/api";
import dayjs from "dayjs";
import _ from 'lodash'
import {nanoid} from "nanoid";
import {userToast} from "helpers/userToast";
import {Button, Input, Table} from "reactstrap";
import {deadlineConfirm} from "helpers/deadlineConfirm";

const SalesOutTable1025 = () => {
    
    const [headerOptions,setHeaderOptions] = React.useState({
        startDate:dayjs().subtract(3, "days").format("YYYY-MM-DD"),
        endDate:dayjs().format("YYYY-MM-DD"),
        subulDate:dayjs().format("YYYY-MM-DD"),
        checkBoxDoneYn : false,
    });
    const [tableList,setTableList] = React.useState([]);
    const [filteredList,setFilteredList] = React.useState([]);

    const handleSearchButtonClick = async ()=>{
        const apiResult = await getResultList241025({param:"",dataBasket:headerOptions})
        const CL = apiResult.map((v)=>{
            return {...v,rowId:nanoid()}
        })
        const CL1 = filterFnc(CL)
        setTableList(CL)
        setFilteredList(CL1)
    }

    const filterFnc = (beforeList=[])=>{
        const {checkBoxDoneYn} = headerOptions ?? ""
        const CL = _.cloneDeep(beforeList)
        const CL1 = CL.filter((f)=>{
            if(checkBoxDoneYn) return f
            if((!checkBoxDoneYn) && (Number(f.quantity) !== Number(f.out_sales_ea))) return f
        })
        return CL1
    }

    const handleSaveButtonClick = async ()=>{
        let check = await deadlineConfirm([{ subul_date: headerOptions?.subulDate }], 'subul_date')
        if(check) return userToast('error','마감이 되었습니다.')
        const apiResult = await saveSalesOut241025({param:"",dataBasket:{headerOptions,filteredList}})
        if(apiResult){
            userToast('','저장완료')
            handleSearchButtonClick()

        }
    }

    const plainInputComponent = ({columnName="", v={}})=>{
        const handleInputChange=(e)=>{
            const {quantity,out_sales_ea} = v ?? 0
            const tempValue = e.target.value ?? 0
            if((Number(quantity) < Number(out_sales_ea) + Number(tempValue)) || Number(tempValue) < 0){
                return userToast('error','생산실적 수량보다 추가로 등록이 불가능합니다.')
            }
            let CL = _.cloneDeep(filteredList)
            const findIndex = _.findIndex(CL,{rowId : v.rowId})
            CL[findIndex] = {...CL[findIndex], [columnName]:tempValue}
            const CL1 = filterFnc(CL)
            setFilteredList(CL1)
        }
        return (
            <Input bsSize={"sm"} type={"text"} value={v[columnName] ?? ""} onChange={handleInputChange} disabled={v.quantity === v.out_sales_ea}  />
        )
    }

    const plainHeaderOptionComponent = ({columnName=""}) => {
        return (
            <Input style={{width:"150px"}} type={"date"} value={headerOptions[columnName]} onChange={(e)=>setHeaderOptions({...headerOptions,[columnName] : e.target.value})}/>
        )
    }

    const checkBoxHeaderOptionComponent = ({columnName="",label=""}) => {
        const handleInputChange=(e)=>{
            setHeaderOptions({
                ...headerOptions,
                [columnName]: !headerOptions[columnName]
            })
        }

        return (
            <div style={{display:"flex",flexDirection:"row",marginLeft:"0.5rem",gap:"5px" ,border:"1px sold lightGray"}}
                 onClick={handleInputChange}
            >
                <span>{label}</span>
                <input type={"checkbox"} value={headerOptions[columnName]} />
            </div>
        );
    }


    return (
        <div style={{marginTop: "1.5rem",flex:4}}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                border: "1px solid lightGray",
                padding:"5px",alignItems:"center", marginBottom:"0.2rem"}}>
                <span>생산일자</span>
                {plainHeaderOptionComponent({columnName:"startDate"})}
                {plainHeaderOptionComponent({columnName:"endDate"})}
                {checkBoxHeaderOptionComponent({columnName:"checkBoxDoneYn",label:"완료포함"})}

                <Button size={"sm"} onClick={()=>handleSearchButtonClick()}>조회</Button>


                {plainHeaderOptionComponent({columnName:"subulDate"})}
                <Button size={"sm"} onClick={()=>handleSaveButtonClick()}>저장</Button>
            </div>
            <div>
                <Table size={"sm"} bordered={true} hover={true}>
                    <thead style={{position: "sticky", top: "2px", zIndex: 5, background: "white"}}>
                    <tr>
                        <th>생산일자</th>
                        <th>고객사</th>
                        <th>제품명</th>
                        <th>규격사이즈</th>
                        <th>생산수량</th>
                        <th>(기존)등록수량</th>
                        <th>(신규)등록수량</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredList && filteredList.map((v) =>{
                        return (
                            <tr key={v.rowId}>
                                <td>{v.result_date}</td>
                                <td>{v.partner_company_name}</td>
                                <td>{v.semi_product_name}</td>
                                <td>{v.order_size}</td>
                                <td>{v.quantity}</td>
                                <td>{v.out_sales_ea}</td>{/*이미 출고 완료한 수량*/}
                                <td style={{padding:0}}>{plainInputComponent({columnName:"insertValue",v:v})}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default SalesOutTable1025;