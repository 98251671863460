import axios from 'axios'
import {getUserCompany, getUserId} from "helpers/getUserInfo";
const BASE_URL = '/apps/salesManagement/order/sujuOrder'

export const getSujuPP241015 = async({ param = '', dataBasket = {} }) => {
    const companyCode = await getUserCompany();

    const params = {
        companyCode,
        param,
        dataBasket,
    }

    const vv = await axios.post(`${BASE_URL}/getSujuPP241015`, {
        params
    })

    if(vv.data.success){
        const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
        return filteredArray
    }

}

export const updateSujuPP241017 = async({ param = '', dataBasket = {} }) => {
    const companyCode = await getUserCompany();
    const userId = await getUserId();

    const params = {
        companyCode,
        userId,
        param,
        dataBasket,
    }

    const vv = await axios.post(`${BASE_URL}/updateSujuPP241017`, {
        params
    })

    if(vv.data.success){
        return {success:true}
    }else{
        return {success:false}
    }

}
