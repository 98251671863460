import React from 'react';
import Style from "../style/result.module.scss";
import ResultTableEachRow0907 from "./ResultTableEachRow";
import ResultTableTotalRow from "./ResultTableTotalRow";
import ResultLossRpm from "./ResultLossRpm";
import TableUnitInfo from "../../../../../../../components/closingCost/TableUnitInfo";

const ResultTableFormat = ({tableList=[],myCompanyYn=1}) => {

    return (
      <div className={`${Style.ResultTable}`}>
        <TableUnitInfo label={"(단위 : m, %, rpm)"} />
        <table>
          <colgroup>
            <col width={'2%'} />
            <col width={'2%'} />

            <col width={'4%'} />
            <col width={'4%'} />
            <col width={'4%'} />
            <col width={'4%'} />
            <col width={'4%'} />
            <col width={'4%'} />
            <col width={'4%'} />
            <col width={'4%'} />
            <col width={'4%'} />
            <col width={'4%'} />
            <col width={'4%'} />
            <col width={'4%'} />
            <col width={'4%'} />
          </colgroup>
          <thead>
            <tr>
              <th>공정</th>
              <th>라인코드</th>
              <th>누계</th>
              <th>1월</th>
              <th>2월</th>
              <th>3월</th>
              <th>4월</th>
              <th>5월</th>
              <th>6월</th>
              <th>7월</th>
              <th>8월</th>
              <th>9월</th>
              <th>10월</th>
              <th>11월</th>
              <th>12월</th>
            </tr>
          </thead>
          <tbody>
            {tableList &&
              tableList.map((line, index) => {
                return <ResultTableEachRow0907 line={line} />
              })}
            {myCompanyYn === 1? (<ResultLossRpm />) : null}
            <ResultTableTotalRow  tableList={tableList}/>
          </tbody>
        </table>
      </div>
    )
};

export default ResultTableFormat;