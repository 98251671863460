import React, {useContext} from 'react';
import Style from '../style/dashboard.module.scss'
import {ReducerDispatchContext, ReducerStateContext} from "../context/index";
import {Button, Input} from "reactstrap";
import {ACTION_TYPES} from "../../../monthlyClosing/report/manufacturingCost/context";
import ReactToPrint from "react-to-print";

const DashBoardHeader = ({printRef}) => {

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);

    const plainInputComponent = ({columnName=""})=>{

        const handleInputChange = (e)=>{
            const options = state.headerOptions
            options[columnName] = e.target.value ?? ""
            dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"headerOptions",value: options})
        }

        return (
            <Input type={"date"}  value={state?.headerOptions[columnName]} bsSize={"sm"} style={{width:"150px"}} onChange={handleInputChange} />
        )
    }


    return (
        <div className={`${Style.header}`}>
            {plainInputComponent({columnName:"baseDate"})}
            <Button size={"sm"} >조회</Button>
            <ReactToPrint
                trigger={() => <Button size={"sm"} >레포트 출력</Button>}
                content={() => printRef.current}
            />
        </div>
    );
};

export default DashBoardHeader;