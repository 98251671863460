import React,{useContext} from 'react';
import {ACTION_TYPES, ReducerStateContext,ReducerDispatchContext} from "../context";
import {Button, Card, Input} from "reactstrap";
import {
    getOutsourcedProcessSalesResult1020,
    saveOutsourcedProcessSalesResult1024
} from "helpers/outsourcing/outsourcedProcessSales/api";
import {nanoid} from "nanoid";
import {userToast} from "helpers/userToast";
import {deadlineConfirm} from "helpers/deadlineConfirm";
import ClosingReportExcel241028 from "helpers/outsourcing/outsourcedProcessSales/component/ClosingReportExcel";

const Header241020 = () => {

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);

    const plainInputComponent = ({columnName="startDate"})=>{
        const handleInputChange = (e)=>{
            const tempValue = e.target.value ?? "";
            let options = state.headerOptions ?? {}
            options[columnName] = tempValue ?? ""
            dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"headerOptions",value: options})
        }
        return (
            <Input bsSize={"sm"} style={{width:"150px"}} type={"date"} value={state.headerOptions[columnName]} onChange={handleInputChange} />
        )
    }

    const handleSearchButtonClick = async ()=>{
        const {startDate,endDate} = state.headerOptions ?? ""
        const apiResult = await getOutsourcedProcessSalesResult1020({param:"",dataBasket:{startDate,endDate}})
        const CL = apiResult.map((v)=>{
            return {...v,rowId:nanoid()}
        })
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"mainTable",value: CL})
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"filteredTableList",value: CL})
    }

    const handleSaveButtonClick = async ()=>{
        let check = await deadlineConfirm([{ subul_date: state?.headerOptions?.subulDate }], 'subul_date')
        if(check) return userToast('error','마감이 되었습니다.')

        const updateList = state.filteredTableList ?? []
        const headerOptions = state.headerOptions ?? {}
        if(!updateList.length >0) return userToast('error','저장할 자료가 없습니다.')

        const apiResult = await saveOutsourcedProcessSalesResult1024({param:"",dataBasket:{updateList,headerOptions}})
        if(apiResult) {
            handleSearchButtonClick()
            return userToast('', '저장 완료')
        }else{
            return
        }
    }

    return (
        <div style={{display:"flex",flexDirection:"row",alignItems:"center",gap:"0.2rem", padding:"5px", border:"1px solid lightGrey"}}>
            <span>생산일자</span>
            {plainInputComponent({columnName:"startDate"})} ~
            {plainInputComponent({columnName:"endDate"})}
            <Button size={"sm"} onClick={handleSearchButtonClick}>조회</Button>
            {/*<span style={{marginLeft:"0.5rem"}}>수불일자</span>*/}
            {/*{plainInputComponent({columnName:"subulDate"})}*/}
            <Button size={"sm"} onClick={handleSaveButtonClick}>저장</Button>
            <ClosingReportExcel241028 startDate={state?.headerOptions?.startDate} endDate={state?.headerOptions?.endDate} />
        </div>
    );
};

export default Header241020;