import React, {useContext} from 'react';
import Style from "../style/table.module.scss";
import _ from 'lodash';
import ClickTableTd from "./ClickTableTd";
import {ReducerDispatchContext, ReducerStateContext} from "../context";
import {getTdBackgroundColor1102} from "../function";

const SubtotalTrComponent = ({thisYear,lastYear}) => {
  const state = useContext(ReducerStateContext) // 5
  const dispatch = useContext(ReducerDispatchContext)

  const monthList = state.monthList

  const getStockSumData = ({ year = 0, month = 0, columnName = '' }) => {
    let CL1 = []
    if (month === 0) CL1 = state.mainTable.filter((f) => f.subul_year === year)
    if (month > 0) CL1 = state.mainTable.filter((f) => f.subul_year === year && f.subul_month === month)

    if (CL1.length === 0) return 0
    const subTotal = _.sumBy(CL1, columnName)
    return _.floor(subTotal / 1)
  }

  const getStockRatio = ({ top = 0, bottom = 0 }) => {
    if (top > 0 && bottom > 0) {
      let amount = _.floor(top / bottom, 1)
      return amount
    }
    return 0
  }

  const MonthSubTotal = ({ year }) => {



    return (
      <>
        {monthList &&
          monthList.map((v) => {
            const tempTop = getStockSumData({ year: year, month: v, columnName: 'now_amount' })
            const tempBottom = getStockSumData({ year: year, month: v, columnName: 'out_amount' })
            return (
              <>
                <td>{tempBottom.toLocaleString()}</td>
                <td>{tempTop.toLocaleString()}</td>
                <td style={{backgroundColor:getTdBackgroundColor1102({top:tempTop,bottom:tempBottom})}}>
                  {getStockRatio({
                    top: tempTop,
                    bottom: tempBottom,
                  })}
                </td>
              </>
            )
          })}
      </>
    )
  }

  return (
    <>
      <tr>
        <td className={`${Style.tableSticky}`} style={{ textAlign: 'left' }} rowSpan={2}>
          합계
        </td>
        <td className={`${Style.textCenter}`}>전년</td>
        <td>{getStockSumData({ year: lastYear, month: 0, columnName: 'out_amount' }).toLocaleString()}</td>
        <td>{getStockSumData({ year: lastYear, month: 0, columnName: 'now_amount' }).toLocaleString()}</td>
        <td>
          {getStockRatio({
            top: getStockSumData({ year: lastYear, month: 0, columnName: 'now_amount' }),
            bottom: getStockSumData({ year: lastYear, month: 0, columnName: 'out_amount' }),
          })}
        </td>
        {MonthSubTotal({ year: lastYear })}
      </tr>
      <tr>
        <td className={`${Style.textCenter}`}>올해</td>
        <td>{getStockSumData({ year: thisYear, month: 0, columnName: 'out_amount' }).toLocaleString()}</td>
        <td>{getStockSumData({ year: thisYear, month: 0, columnName: 'now_amount' }).toLocaleString()}</td>
        <td>
          {getStockRatio({
            top: getStockSumData({ year: thisYear, month: 0, columnName: 'now_amount' }),
            bottom: getStockSumData({ year: thisYear, month: 0, columnName: 'out_amount' }),
          })}
        </td>
        {MonthSubTotal({ year: thisYear })}
      </tr>
    </>
  )
};

export default SubtotalTrComponent;