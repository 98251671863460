// @flow
import jwtDecode from 'jwt-decode'
import { Cookies } from 'react-cookie'
import axios from 'axios'
import { getUserId } from 'helpers/getUserInfo'
import * as FNC from '../components/SessionTime/function'
import { userToast } from 'helpers/userToast'

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const user = getLoggedInUser()
  if (!user) {
    return false
  }
  const token = sessionStorage.getItem('token')
  const decoded = jwtDecode(token)
  const currentTime = Date.now() / 1000
  // console.log('토큰 만료 여부 점검')
  // console.log('현재시간(0932) : '+currentTime)
  // console.log('만료시간(0932) : '+decoded.exp)
  // console.log('남은시간(0932) : '+ (decoded.exp - currentTime )/60 + '분')
  if (decoded.exp < currentTime) {
    console.warn('access token expired')
    return false
  } else {
    return true
  }
}

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
  const user = sessionStorage.getItem('user')
  return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null
}

const checkRefreshTokenValidYn = async (setRefreshTokenStatus) => {
  // accessToken 연장 또는 RefreshToken 만료 여부 점검
  const userId = getUserId()

  await axios
    .post(`/user/validCheckRefreshToken`, { userId })
    .then(function (response) {
      if (response?.data?.successType === 'refresh') {
        const accessToken = response.data.data
        sessionStorage.removeItem('token')
        sessionStorage.setItem('token', accessToken)
      }
      if (response?.data?.successType === 'expired') {
        setRefreshTokenStatus('expired')
        alert(`지정한 로그인 시간이 만료되었습니다. `)
        return (window.location.href = '/account/login')
      }
    })
    .catch(function (error) {
      console.log('err', error)
      // userToast('error', `에러점검 ${response?.data?.successType}`)
      alert(`로그인 연장과정에서 오류가 발생하였습니다.`)
      // return (window.location.href = '/account/login')
    })
}

export { isUserAuthenticated, getLoggedInUser, checkRefreshTokenValidYn }
