import dayjs from "dayjs";
import {createContext} from "react";


export const INIT_STATE = {
    users: [
    ],
    headerOptions:{
        startDate:dayjs().subtract(0, "d").format("YYYY-MM-DD"),
        endDate:dayjs().format("YYYY-MM-DD"),
        lineOptions : []
    },
    selectedRowId:null,
    initObj :{
        partner_company_id : null,
        product_id : null,
        product_process_id : null,
        order_date_time : null,
        quantity_my : null,
        quantity_price : null,
        febric_ea : null,
        remark : null,
    },
    groupedProduct:[], // product에 할당된 pp 리스트 찾기
    uniqueProduct:[],
    ppList:[],
    partnerList:[],

    mainTable:[],
    filteredTableList:[],

};


export const ACTION_TYPES = {
    FETCH_START: "CREATE_USER",
    ARRAY_CHANGE: "ARRAY_CHANGE",
    OBJECT_CHANGE: "OBJECT_CHANGE",
};


export const mainReducerActions = (state, action) => {
    switch (action.type) {
        case "REMOVE_USER":
            return {
                ...state,
                users: state.users.filter((user) => user.id !== action.id),
            };

        case "OBJECT_CHANGE":
            const {columnName,name,value} = action
            return {
                ...state,
                [columnName]: {...state[columnName], [name] : value},
            };
        case "ARRAY_CHANGE":
            return {
                ...state,
                [action.columnName]: action.value,
            };

        default:
            throw new Error("Unhandled action");
    }
};


export const ReducerStateContext = createContext({})
export const ReducerDispatchContext = createContext({})