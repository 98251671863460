import React, {useContext, useEffect, useState} from 'react';
import Style from "../style/result.module.scss";
import _ from 'lodash'
import {ReducerDispatchContext, ReducerStateContext} from "../context";

const ResultTableEachRow0907 = ({line}) => {

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);


    const getSubTotalAmount = ({columnName="amount_total"})=>{
        const {main_class_name} = line ?? ""
        const CL1 = _.cloneDeep(state.mainTable)
        const CL2 = CL1.filter((f)=> f.main_class_name === main_class_name) ?? []
        const CL3 = _.sumBy(CL2,columnName) ?? 0
        return (CL3).toLocaleString()
    }

    return (
        <>
            <tr>
                <td>{line.main_class_name ?? ""}</td>
                <td>{line.product_line_code ?? ""}</td>
                <td>{(line.amount_total).toLocaleString() ?? "-"}</td>
                <td>{(line.amount_1).toLocaleString() ?? "-"}</td>
                <td>{(line.amount_2).toLocaleString() ?? "-"}</td>
                <td>{(line.amount_3).toLocaleString() ?? "-"}</td>
                <td>{(line.amount_4).toLocaleString() ?? "-"}</td>
                <td>{(line.amount_5).toLocaleString() ?? "-"}</td>
                <td>{(line.amount_6).toLocaleString() ?? "-"}</td>
                <td>{(line.amount_7).toLocaleString() ?? "-"}</td>
                <td>{(line.amount_8).toLocaleString() ?? "-"}</td>
                <td>{(line.amount_9).toLocaleString() ?? "-"}</td>
                <td>{(line.amount_10).toLocaleString() ?? "-"}</td>
                <td>{(line.amount_11).toLocaleString() ?? "-"}</td>
                <td>{(line.amount_12).toLocaleString() ?? "-"}</td>
            </tr>
            {line.main_class_number === 1 ? (
                <tr style={{backgroundColor:"rgba(232,232,232,0.33)"}}>
                    <td colSpan={2}>소계</td>
                    <td>{getSubTotalAmount({columnName:"amount_total"})}</td>
                    <td>{getSubTotalAmount({columnName:"amount_1"})}</td>
                    <td>{getSubTotalAmount({columnName:"amount_2"})}</td>
                    <td>{getSubTotalAmount({columnName:"amount_3"})}</td>
                    <td>{getSubTotalAmount({columnName:"amount_4"})}</td>
                    <td>{getSubTotalAmount({columnName:"amount_5"})}</td>
                    <td>{getSubTotalAmount({columnName:"amount_6"})}</td>
                    <td>{getSubTotalAmount({columnName:"amount_7"})}</td>
                    <td>{getSubTotalAmount({columnName:"amount_8"})}</td>
                    <td>{getSubTotalAmount({columnName:"amount_9"})}</td>
                    <td>{getSubTotalAmount({columnName:"amount_10"})}</td>
                    <td>{getSubTotalAmount({columnName:"amount_11"})}</td>
                    <td>{getSubTotalAmount({columnName:"amount_12"})}</td>
                </tr>
            ) : null}
        </>
    );
};

export default ResultTableEachRow0907;