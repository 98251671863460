import React, {useEffect, useState} from 'react';
import {userToast} from "helpers/userToast";
import {getDailyReturnInfo0911, getDailySalesAmount0911, getDailySujuInfo0911} from "../api";
import _ from 'lodash'
import {getRatio0911} from "../function";
import Style from '../style/dashboard.module.scss'
import {Table} from "reactstrap";

const ReturnDailyInfo0912 = ({options={}}) => {

    const [tableList,setTableList] = useState([])

    const initFnc= async ()=>{
        const apiResult = await getDailyReturnInfo0911({param:"",dataBasket:{...options}})
        console.log("apiResult_09121101_",apiResult)
        setTableList(apiResult)
    }

    const getTableTd = ({classType="",productYn=0,columnName=""})=>{
        if(!tableList.length > 0) return 0
        const CL1 = _.cloneDeep(tableList)
        const CL2 = CL1.filter((f)=> f.product_yn === productYn && f.class_type === classType)

        if(CL2.length > 0){
            return _.floor(CL2[0][columnName],0) ?? 0
        }else{
            return 0
        }
    }


    const getTableSubtotalTd = ({classType="" ,columnName=""})=>{
        const CL1 = _.cloneDeep(tableList)
        const CL2 = CL1.filter((f)=> f.class_type === classType)

        if(CL2.length > 0){
            const tempValue = _.sumBy(CL2,columnName) ?? 0
            return _.floor(tempValue,0) ?? 0
        }else{
            return 0
        }
    }

    useEffect(()=>{
        if(options.thisYear > 0){
            initFnc()
        }
    },[options])


    return (
        <div className={`${Style.wrapper}`}>
            <span className={`${Style.subTitle}`}>반품 등록현황</span>
            <Table bordered={true}  size={"sm"}>
                <thead>
                <tr>
                    <th rowSpan="2">구분</th>
                    <th colSpan="2">당 일</th>
                    <th colSpan="2">누 계</th>
                </tr>
                <tr>
                    <th>건수</th>
                    <th>금액</th>
                    <th>건수</th>
                    <th>금액</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>제품</td>
                    <td>{getTableTd({classType:"당일",productYn:0,columnName:"cnt"}).toLocaleString().toLocaleString()}</td>
                    <td>{getTableTd({classType:"당일",productYn:0,columnName:"price_supply"}).toLocaleString()}</td>
                    <td>{getTableTd({classType:"누계",productYn:0,columnName:"cnt"}).toLocaleString()}</td>
                    <td>{getTableTd({classType:"누계",productYn:0,columnName:"price_supply"}).toLocaleString()}</td>
                </tr>
                <tr>
                    <td>상품</td>
                    <td>{getTableTd({classType:"당일",productYn:1,columnName:"cnt"}).toLocaleString()}</td>
                    <td>{getTableTd({classType:"당일",productYn:1,columnName:"price_supply"}).toLocaleString()}</td>
                    <td>{getTableTd({classType:"누계",productYn:1,columnName:"cnt"}).toLocaleString()}</td>
                    <td>{getTableTd({classType:"누계",productYn:1,columnName:"price_supply"}).toLocaleString()}</td>
                </tr>
                <tr>
                    <td>합계</td>
                    <td>{getTableSubtotalTd({classType:"당일",columnName:"cnt"}).toLocaleString()}</td>
                    <td>{getTableSubtotalTd({classType:"당일",columnName:"price_supply"}).toLocaleString()}</td>
                    <td>{getTableSubtotalTd({classType:"누계",columnName:"cnt"}).toLocaleString()}</td>
                    <td>{getTableSubtotalTd({classType:"누계",columnName:"price_supply"}).toLocaleString()}</td>
                </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default ReturnDailyInfo0912;