import React, {useContext} from 'react';
import Style from '../style/result.module.scss'
import _ from  'lodash'
import {ReducerDispatchContext, ReducerStateContext} from "../context";
import ResultTableEachRow0907 from "./ResultTableEachRow";
import ResultTableTotalRow from "./ResultTableTotalRow";
import ResultTableFormat from "./ResultTableFormat";

const ResultTable0907 = () => {

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);

    const uniqLine = _.uniqBy(state.lineInfoTable,"main_class_name")



    return (
        <div>
            <h3>자사 실적현황</h3>
            <ResultTableFormat tableList={state.mainTable} myCompanyYn ={1} />
            <h3>외주 실적현황</h3>
            <ResultTableFormat tableList={state.subTable}  myCompanyYn ={0}  />
        </div>
    );
};

export default ResultTable0907;