import React, {useState, useReducer, useEffect, useRef} from 'react';
import _ from 'lodash'
import {DownloadTableExcel} from "react-export-table-to-excel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Style from './style/table.module.scss'
import {ACTION_TYPES,INIT_STATE, ReducerStateContext, ReducerDispatchContext, mainReducerActions} from "./context";
import MonthlyStockTable0830 from "./component/MonthlyStockTable";
import {Button, Card, Input} from "reactstrap";
import {getClosingStock0830} from "./api";
import StockDetailTable0830 from "./component/StockDetailTable";
import TableUnitInfo from "../../../../../../components/closingCost/TableUnitInfo";



const StockStatus0830 = () => {
  const [state, dispatch] = useReducer(mainReducerActions, INIT_STATE)
  const tableRef = useRef(null)

  const handleSearchButtonClick = async () => {
    const startDate = state.headerOptions.startDate
    const apiResult = await getClosingStock0830({ param: '', dataBasket: { startDate } })
    dispatch({ type: ACTION_TYPES.ARRAY_CHANGE, columnName: 'mainTable', value: apiResult })
  }

  useEffect(() => {
    if (state.mainTable.length > 0) {
      const CL1 = _.groupBy(state.mainTable, 'partner_company_id')
      dispatch({ type: ACTION_TYPES.ARRAY_CHANGE, columnName: 'groupedByPartner', value: CL1 })

      const CL2 = _.uniqBy(state.mainTable, 'partner_company_id')
      dispatch({ type: ACTION_TYPES.ARRAY_CHANGE, columnName: 'uniqByPartner', value: CL2 })
    }
  }, [state.mainTable])

  const handleInputChange = (e)=>{
    const {name,value} = e.target ?? ""
    let beforeHeaderOptions = state.headerOptions
    beforeHeaderOptions[name] = value ?? ""
    dispatch({ type: ACTION_TYPES.ARRAY_CHANGE, columnName: 'headerOptions', value: beforeHeaderOptions })
  }


  return (
    <ReducerStateContext.Provider value={state}>
      <ReducerDispatchContext.Provider value={dispatch}>
        <Card style={{ padding: '0.4rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={`${Style.headerInfo}`}>
              <Input type={'date'} bsSize={'sm'} style={{ width: '150px' }} value={state.headerOptions.startDate} name={"startDate"} onChange={(e)=>handleInputChange(e)} />
              <Button
                size={'sm'}
                onClick={() => {
                  handleSearchButtonClick()
                }}
              >
                조회
              </Button>
              <DownloadTableExcel
                filename="연간 거래처별 재고현황"
                sheet="StockStatus"
                currentTableRef={tableRef.current}
              >
                <FontAwesomeIcon
                  icon={solid('file-excel')}
                  size={'3x'}
                  color={'#0ca678'}
                  style={{ cursor: 'pointer', marginTop: '0.1rem' }}
                />
              </DownloadTableExcel>
                <ExplainSpan/>
            </div>
            <TableUnitInfo label={'(단위 : 천원, 개월/월)'} />
            <MonthlyStockTable0830 tableRef={tableRef} />
            <div>
              <StockDetailTable0830 />
            </div>
          </div>
        </Card>
      </ReducerDispatchContext.Provider>
    </ReducerStateContext.Provider>
  )
}

export default StockStatus0830

const ExplainSpan = () => {
  return (
    <span style={{ backgroundColor: '#0ca678', color: 'white',display:"flex",flexDirection:"row",alignContent:"center" ,fontSize:"0.9rem"}}>
      (※ 전체 목록을 다운로드 시, 테이블 하단의 조회 목록을 최대로 확장 후 엑셀다운로드를 진행하시기 바랍니다.){' '}
    </span>
  )
}