import React, {useContext, useEffect, useState} from 'react';
import Style from "../style/result.module.scss";
import _ from 'lodash'
import {ReducerDispatchContext, ReducerStateContext} from "../context";

const ResultTableTotalRow = ({tableList =[]}) => {

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);


    const getSubTotalAmount = ({columnName="amount_total"})=>{
        const CL1 = _.cloneDeep(tableList)
        const CL3 = _.sumBy(CL1,columnName) ?? 0
        return (CL3).toLocaleString()
    }

    return (
        <>
            <tr style={{backgroundColor:"rgba(140,139,139,0.33)"}}>
                <td colSpan={2}>총   계</td>
                <td>{getSubTotalAmount({columnName:"amount_total"})}</td>
                <td>{getSubTotalAmount({columnName:"amount_1"})}</td>
                <td>{getSubTotalAmount({columnName:"amount_2"})}</td>
                <td>{getSubTotalAmount({columnName:"amount_3"})}</td>
                <td>{getSubTotalAmount({columnName:"amount_4"})}</td>
                <td>{getSubTotalAmount({columnName:"amount_5"})}</td>
                <td>{getSubTotalAmount({columnName:"amount_6"})}</td>
                <td>{getSubTotalAmount({columnName:"amount_7"})}</td>
                <td>{getSubTotalAmount({columnName:"amount_8"})}</td>
                <td>{getSubTotalAmount({columnName:"amount_9"})}</td>
                <td>{getSubTotalAmount({columnName:"amount_10"})}</td>
                <td>{getSubTotalAmount({columnName:"amount_11"})}</td>
                <td>{getSubTotalAmount({columnName:"amount_12"})}</td>
            </tr>
        </>
    );
};

export default ResultTableTotalRow;