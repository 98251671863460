import axios from 'axios'
import { getUserCompany, getUserId } from 'helpers/getUserInfo'

export const insertUserApiAccessInfo = async (url) => {
  const userCompanyCode = await getUserCompany()
  const userId = await getUserId()
  const vv = await axios.post('/api/userApiAccessInfo/insertUserApiAccessInfo', {
    headers: {
      'x-access-token': sessionStorage.getItem('token'),
    },
    userCompanyCode: userCompanyCode,
    userId: userId,
    url: url,
  })
  return vv.data
}
