import React, {useContext, useEffect, useState} from 'react';
import Chart from 'react-apexcharts';
import { Card, CardBody, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {ReducerDispatchContext, ReducerStateContext} from "../context";
import {getDataByMonth} from "../function";
import dayjs from "dayjs";
import _ from 'lodash'

const YearlyTableGraph = () => {

    const state = useContext(ReducerStateContext) // 5
    const dispatch = useContext(ReducerDispatchContext)
    const [lastYear,setLastYear]= useState([])
    const [thisYear,setThisYear]= useState([])
    const [maxMin,setMaxMin]= useState({max:0,min:0})

    const thisYearNumber = Number(dayjs(state.headerOptions.baseDate).format("YYYY"))
    const lastYearNumber = Number(thisYearNumber - 1)


    const xCategory = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

    const apexOpts = {
        chart: {
            height: 350,
            type: 'line',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
            },
            toolbar: {
                show: false
            }
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
            enabled: true,
        },
        stroke: {
            curve: 'smooth'
        },
        title: {
            text: '연간 매출액 정보',
            align: 'left'
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        markers: {
            size: 1
        },
        xaxis: {
            categories: xCategory,
            title: {
                text: '월'
            }
        },
        yaxis: {
            title: {
                text: '매출액'
            },
            min: maxMin.min,
            max: maxMin.max * 1.3
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
        }
    }
    const apexData = [{
        name:"전년",
        data: lastYear
    }, {
        name:"올해",
        data: thisYear
    }];


    useEffect(()=>{
        if(state.mainTable.length>0){
            const CL = state.mainTable
            const CL1 = getDataByMonth({xCategory,baseTable:CL,year:thisYearNumber})
            setThisYear(CL1)

            const CL2 = getDataByMonth({xCategory,baseTable:CL,year:lastYearNumber})
            setLastYear(CL2)

            const findMax = _.maxBy(CL,"out_amount")?.out_amount *1.4 /1000000 ?? 0
            const findMin = _.minBy(CL,"out_amount")?.out_amount *0.8 /1000000 ?? 0
            setMaxMin({max: findMax,min:findMin})

        }
    },[state.mainTable])


    return (
        <div style={{width:"100%"}}>
            <h1 style={{textAlign:"center"}}>연간 매출금액 현황</h1>
            <Chart options={apexOpts} series={apexData} type="line" height={352} className="apex-charts mt-3" />
        </div>
    );
};

export default YearlyTableGraph;