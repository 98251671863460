import React from 'react';

const TableUnitInfo = ({label=""}) => {
    return (
        <div style={{width:"100%",display:"flex",flexDirection:"row",gap:"8px",justifyContent:"flex-end",backgroundColor:"",marginBottom:"0.5rem"}}>
            <span style={{fontSize:"0.8rem"}}>{label}</span>
        </div>
    );
};

export default TableUnitInfo;