import React, {useContext, useEffect, useState} from 'react';
import {ReducerDispatchContext, ReducerStateContext} from "helpers/outsourcing/outsourcedProcessSales/context";
import {userToast} from "helpers/userToast";
import {Table} from "reactstrap";
import {getRecentClosingList241028} from "helpers/outsourcing/outsourcedProcessSales/api";
import {nanoid} from "nanoid";

const RecentCostPrice241028 = () => { // 최근 마감한 단가

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);
    const [tableList,setTableList] = useState([]);

    const getCostInfo = ()=>{
        if(tableList.length > 0){
            const {partner_company_name,semi_product_name} = tableList[0] ?? ""
            return(
                <span>{partner_company_name} <br/> {semi_product_name}</span>
            )
        }
        return ''
    }



    useEffect(() => {
        const {partner_company_id,product_process_id} = state.selectedRowObj ?? {}

        if(product_process_id > 0 && partner_company_id > 0 ){
            async function getRecentClosingList(){
                const apiResult = await getRecentClosingList241028({param:"",dataBasket:{product_process_id,partner_company_id}})
                const CL = apiResult.map((v)=>{
                    return {...v,rowId:nanoid()}
                })
                setTableList(CL)
            }

            getRecentClosingList()
        }




    }, [state.selectedRowObj]);


    return (
        <div style={{marginTop: "1.5rem", flex: 1, padding:"0.5rem", border:"1px solid lightGrey", height:"100%", overflow:"auto"}}>
            <h4>최근 단가 정산 내역</h4>
            <p style={{textAlign:"right"}}>{getCostInfo()}</p>
            <Table size={"sm"} bordered={true} hover={true}>
                <thead>
                    <tr>
                        <th>일자</th>
                        <th>규격</th>
                        <th>수량</th>
                        <th>단가</th>
                    </tr>
                </thead>
                <tbody>
                {tableList && tableList.map((v)=>{
                    return (
                        <tr key={v.rowId}>
                            <td>{v.result_date}</td>
                            <td>{v.order_size}</td>
                            <td>{v.quantity}</td>
                            <td style={{fontWeight:"bold",textDecoration:"underline"}}>{v.out_price}</td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </div>
    );
};

export default RecentCostPrice241028;