import React from 'react';

export const validateInsertData = (tableList=[])=>{
    let errorCount = 0;

    tableList.forEach((item)=>{
        if(!item.partner_company_id > 0) errorCount++
        if(!item.product_process_id > 0) errorCount++
        if(!item.order_date_time) errorCount++
        if(!item.quantity_my > 0) errorCount++
    })

    return errorCount

}