import React, {useContext, useEffect, useState} from 'react';
import Style from "../style/table.module.scss";
import _ from "lodash";
import {ReducerDispatchContext, ReducerStateContext} from "../context";
import TableUnitInfo from "../../../../../../../components/closingCost/TableUnitInfo";

const StockDetailTableRight = () => {

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);

    const [tableList,setTableList] = useState([])

    useEffect(()=>{
        const {partner_company_id,product_process_id} = state.detailSelectedInfo ?? ""
        if(partner_company_id > 0){
            const CL1 = state.detailGroupedByPp[product_process_id] ?? ""
            const CL2 = _.sortBy(CL1,"subul_month")
            setTableList(CL2)
            // console.log("CL1)08885)",CL1)
        }else{
            setTableList([])
        }
    },[state.detailSelectedInfo])


    return (
        <div style={{height:"20vh",overflow:"auto",flex:1}} className={`${Style.detailMonthlyTable}`}>
            <h3>{tableList[0]?.semi_product_name ??""}</h3>
            <TableUnitInfo label={"(단위 : 원, 원, 개월/월)"} />
            <table size={"sm"}>
                <thead>
                <tr>
                    <th>매출년도</th>
                    <th>매출월</th>
                    <th>재고금액</th>
                    <th>판매금액</th>
                    <th>재고월수</th>
                </tr>
                </thead>
                <tbody>
                {state.detailThisMonth && tableList.map((v)=>{
                    return (
                        <tr>
                            <td>{v.subul_year}</td>
                            <td>{v.subul_month}</td>
                            <td>{_.ceil(v.now_amount,0).toLocaleString() ?? ""}</td>
                            <td style={{backgroundColor:"#d1d1ec"}}>{_.ceil(v.out_amount,0).toLocaleString() ?? ""}</td>
                            <td>{_.floor(v.amount_per_monthly,1) ?? ""}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
};

export default StockDetailTableRight;