import React, {useState, useReducer, useEffect} from 'react';
import {ACTION_TYPES,INIT_STATE, ReducerStateContext, ReducerDispatchContext, mainReducerActions} from "./context";
import HeaderSujuOP from "./component/Header";
import MainTable from "./component/MainTable";
import {userToast} from "helpers/userToast";
import {getPartnerCompanyList, getUserProductProcessId} from "helpers/getUserInfo";
import {convertEachGroupedList} from "./function";
import _ from "lodash";

const SujuOrderOutsourcedProcess = () => {
    const [state,dispatch] = useReducer(mainReducerActions,INIT_STATE )

    const convertEachGroupedList = (tableList=[])=>{
        const groupedProduct = _.groupBy(tableList,"product_id")
        const uniqueProduct = _.unionBy(tableList,"product_id").map((v)=>{
            return {...v,value:v.product_id, label:v.product_name}
        })
        return {groupedProduct,uniqueProduct}
    }

    useEffect(() => {
       async function getInitialState() {
           const partnerList = await getPartnerCompanyList()
           const CL = await getUserProductProcessId()
           const ppList = CL.filter((f)=>f.product_process_order > 0)
           const {groupedProduct,uniqueProduct} = convertEachGroupedList(ppList)

           // console.log('groupedProduct_241016_1857_',groupedProduct)
           // console.log('uniqueProduct_241016_1857_',uniqueProduct)
           // console.log('ppList_241016_1857_',ppList)
           // console.log('partnerList_241016_1857_',partnerList)

           dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"groupedProduct",value: groupedProduct})
           dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"uniqueProduct",value: uniqueProduct})
           dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"ppList",value: ppList})
           dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"partnerList",value: partnerList})
        }

        getInitialState()
    }, []);


    return (
        <ReducerStateContext.Provider value = {state}>
            <ReducerDispatchContext.Provider value={dispatch}>
                <div>
                    <HeaderSujuOP />
                    <MainTable />
                </div>
            </ReducerDispatchContext.Provider>
        </ReducerStateContext.Provider>

    );
};

export default SujuOrderOutsourcedProcess;