import React, {useReducer, useRef, useState} from 'react';
import DashBoardHeader from "./components/DashBoardHeader";
import {ACTION_TYPES,INIT_STATE, ReducerStateContext, ReducerDispatchContext, mainReducerActions} from "./context";
import DashBoardBody from "./components/DashBoardBody";
import  './style/reportPrint.css'
import {getUserType} from "helpers/getUserInfo";

const DailyDashBoard0907 = () => {

    const [state,dispatch] = useReducer(mainReducerActions,INIT_STATE )
    const [userType,setUserType] = useState(getUserType())
    const printRef = useRef(null)



    if(userType !== "A") return null

    return (
        <ReducerStateContext.Provider value = {state}>
            <ReducerDispatchContext.Provider value = {dispatch}>
                <DashBoardHeader printRef={printRef}/>
                <DashBoardBody printRef={printRef}/>
            </ReducerDispatchContext.Provider>
        </ReducerStateContext.Provider>
    );
};

export default DailyDashBoard0907;