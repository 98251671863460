import React, {useContext, useEffect} from 'react';
import {ACTION_TYPES, ReducerDispatchContext, ReducerStateContext} from "../context/index";
import {Button, Input} from "reactstrap";
import dayjs from "dayjs";
import {getResultLineInfo0907, getResultMonthly0911, getResultMonthly0913, getResultMonthly1004} from "../api";
import {addMainClass} from "../function";


const ResultHeader0907 = () => {

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);

    const handleInputChange = (e,columnName="") =>{
        const options = state.headerOptions
        options[columnName] = e.target.value ?? ""
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"headerOptions",value: options})
    }

    const handleSearchButtonClick = async ()=>{
        const thisYear = Number(dayjs(state.headerOptions.baseDate).format("YYYY"))
        const lastYear = Number(thisYear - 1)
        const myCompanyResult = await getResultMonthly0911({param:"",dataBasket:{thisYear,lastYear}})
        const myCompanyLossRpmResult = await getResultMonthly0913({param:"",dataBasket:{thisYear,lastYear}})
        const outsourcingResult = await getResultMonthly1004({param:"",dataBasket:{thisYear,lastYear}})

        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"mainTable",value: myCompanyResult})
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"myLossRpmTable",value: myCompanyLossRpmResult})
        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName:"subTable",value: outsourcingResult})
        // console.log("apiResult_0906_1640_",myCompanyResult)
    }

    useEffect(()=>{
        handleSearchButtonClick()
    },[])


    return (
        <div style={{ width: '100%', display:"flex",flexDirection:"row" ,gap:"8px",marginBottom:"2rem"}}>
            <Input
                type={'date'}
                style={{ width: '150px' }}
                bsSize={'sm'}
                value={state.headerOptions.baseDate}
                onChange={(e) => handleInputChange(e, 'baseDate')}
            />
            <Button size={"sm"} onClick={handleSearchButtonClick}>조회</Button>
            {/*<Button size={"sm"} onClick={onPrint}>인쇄</Button>*/}
        </div>
    );
};

export default ResultHeader0907;