import axios from 'axios'
import {getUserCompany, getUserId} from "helpers/getUserInfo";
const BASE_URL = '/apps/salesManagement/order/sujuOrder'

export const getOutsourcedProcessSalesResult1020 = async({ param = '', dataBasket = {} }) => {
    const companyCode = getUserCompany();

    const params = {
        companyCode,
        param,
        dataBasket,
    }

    const vv = await axios.post(`${BASE_URL}/getOutsourcedProcessSalesResult1020`, {
        params
    })

    if(vv.data.success){
        const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
        return filteredArray
    }

}

export const getResultList241025 = async({ param = '', dataBasket = {} }) => {
    const companyCode = getUserCompany();

    const params = {
        companyCode,
        param,
        dataBasket,
    }

    const vv = await axios.post(`${BASE_URL}/getResultList241025`, {
        params
    })

    if(vv.data.success){
        const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
        return filteredArray
    }

}


export const saveOutsourcedProcessSalesResult1024 = async({ param = '', dataBasket = {} }) => {
    const companyCode = getUserCompany();

    const params = {
        companyCode,
        param,
        dataBasket,
    }

    const vv = await axios.post(`${BASE_URL}/saveOutsourcedProcessSalesResult1024`, {
        params
    })

    if(vv?.data?.success){
        return true
    }else{
        return false
    }

}

export const saveSalesOut241025 = async({ param = '', dataBasket = {} }) => {
    const companyCode = getUserCompany();
    const userId = getUserId()
    const params = {
        companyCode,
        userId,
        param,
        dataBasket,
    }

    const vv = await axios.post(`${BASE_URL}/saveSalesOut241025`, {
        params
    })

    if(vv?.data?.success){
        return true
    }else{
        return false
    }

}


export const getRecentClosingList241028 = async({ param = '', dataBasket = {} }) => {
    const companyCode = getUserCompany();

    const params = {
        companyCode,
        param,
        dataBasket,
    }

    const vv = await axios.post(`${BASE_URL}/getRecentClosingList241028`, {
        params
    })

    if(vv.data.success){
        const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
        return filteredArray
    }

}

export const getClosingSubTotal241028 = async({ param = '', dataBasket = {} }) => {
    const companyCode = getUserCompany();

    const params = {
        companyCode,
        param,
        dataBasket,
    }

    const vv = await axios.post(`${BASE_URL}/getClosingSubTotal241028`, {
        params
    })

    if(vv.data.success){
        const filteredArray = vv.data.data?.filter((v) => v.fieldCount != 0)[0]
        return filteredArray
    }

}
