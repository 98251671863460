import React from 'react';

const EachRowSubInfo = (eachRow) => {
    const ErrorMsg = (props)=>{
        return <span style={{background:"whitesmoke",color:"#fa5c7c"}}>{props.children}</span>
    }
    return(
        <div style={{display:"flex",flexDirection:"row",gap:"5px",justifyContent:"flex-start",marginBottom:"0.3rem",borderBottom:"1px solid whitesmoke"}}>
            {eachRow.partner_company_id > 0 ? null : (<ErrorMsg>고객사</ErrorMsg>)}
            {eachRow.product_id > 0 ? null : (<ErrorMsg>제품명</ErrorMsg>)}
            {eachRow.product_process_id > 0 ? null : (<ErrorMsg>공정명</ErrorMsg>)}
            {eachRow.order_date_time  ? null : (<ErrorMsg>납기일자</ErrorMsg>)}
            {eachRow.quantity_my > 0 ? null : (<ErrorMsg>자사수량</ErrorMsg>)}
        </div>
    )
};

export default EachRowSubInfo;
