import React, {useEffect, useState} from 'react';
import {userToast} from "helpers/userToast";
import {getDailySalesAmount0911, getDailySujuInfo0911, getSujuCancelCheck0912} from "../api";
import _ from 'lodash'
import {getRatio0911} from "../function";
import Style from '../style/dashboard.module.scss'
import {Table} from "reactstrap";

const SujuCancel0912 = ({options={}}) => {

    const [tableList,setTableList] = useState([])

    const initFnc= async ()=>{
        const apiResult = await getSujuCancelCheck0912({param:"",dataBasket:{...options}})
        console.log("apiResult_0912_1723_",apiResult)
        setTableList(apiResult)
    }

    const getTableTd = ({classType="",productYn=0,columnName=""})=>{
        if(!tableList.length > 0) return 0
        const CL1 = _.cloneDeep(tableList)
        const CL2 = CL1.filter((f)=> f.product_yn === productYn && f.class_type === classType)

        if(CL2.length > 0){
            return _.floor(CL2[0][columnName],0) ?? 0
        }else{
            return 0
        }
    }
    const getTableSubtotalTd = ({classType="",columnName=""})=>{
        if(!tableList.length > 0) return 0
        const CL1 = _.cloneDeep(tableList)
        const CL2 = CL1.filter((f)=> f.class_type === classType)

        if(CL2.length > 0){
            const tempValue = _.sumBy(CL2,columnName) ?? 0
            return _.floor(tempValue,0) ?? 0
        }else{
            return 0
        }
    }

    useEffect(()=>{
        if(options.thisYear > 0){
            initFnc()
        }
    },[options])




    return (
       <>
           {tableList.length > 0 ? (
               <div className={`${Style.wrapper}`} style={{width:"100%"}}>
                   <span className={`${Style.subTitle}`}>생산실적내역(수주취소 내역 점검)_최근 1개월</span>
                   <Table bordered={true}  size={"sm"}>
                       <colgroup>
                           <col width={'1%'} />
                           <col width={'2%'} />
                           <col width={'4%'} />
                           <col width={'1%'} />
                           <col width={'3%'} />
                           <col width={'7%'} />
                       </colgroup>
                       <thead>
                       <tr>
                           <th rowSpan="2">구분</th>
                           <th>수주</th>
                           <th>제품</th>
                           <th>담당</th>
                           <th>비고</th>
                           <th>실적점검(자사/외주)</th>
                       </tr>

                       </thead>
                       <tbody>
                       {(tableList.length >0) && tableList.map((v,i)=>{
                           return (
                               <tr>
                                   <td>{i+1}</td>
                                   <td>{v.suju_number}</td>
                                   <td style={{textAlign:"left"}}>{v.product_name}</td>
                                   <td>{v.employee_name}</td>
                                   <td style={{fontSize:"0.8rem"}}>{v.remark ?? ""}{v.label_note ?? ""}</td>
                                   <td style={{fontSize:"0.8rem"}}>{v.my_result_ea ?? ""}{v.outsourcing_result_ea ?? ""}</td>
                               </tr>
                           )
                       })}

                       </tbody>
                   </Table>
                   <span className={`${Style.bottomComment}`}>(※수주정보가 취소 이전에 생산실적이 등록된 경우입니다. '실적 및 재고현황' 재점검 필요합니다.)</span>
               </div>
           ) : null}
       </>
    );
};

export default SujuCancel0912;