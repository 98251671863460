import React, {useRef} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import {getClosingSubTotal241028} from "helpers/outsourcing/outsourcedProcessSales/api";
import {Table} from "reactstrap";
import {useReactToPrint} from "react-to-print";
import {userToast} from "helpers/userToast";
import days from "dayjs";



const ClosingReportExcel241028 = ({startDate="",endDate=""}) => {


    const printRef = useRef()
    const handleBtnPrintClick = useReactToPrint({
        content: () => printRef.current,
    })

    // 기간 마감내역 엑셀 다운로드
    const [tableList,setTableList] = React.useState([]);

    const handleExcelExportClick = async () => {
        const apiResult = await getClosingSubTotal241028({param:"",dataBasket:{startDate,endDate}})
        setTableList(apiResult);
        userToast('','인쇄 준비중입니다.',0.2)

        setTimeout(()=>{
            handleBtnPrintClick()
        },1500)


    }

    const trStyleObj = (v)=>{
        let styleObj = {}
        if(v.partner_company_name === "소계"){
            styleObj.backgroundColor = "#ededed"
        }
        if(v.partner_company_name === "총계"){
            styleObj.backgroundColor = "#505050"
            styleObj.color = "#ffffff"
        }

        return styleObj
    }

    return (
        <div style={{marginLeft: '0.5rem'}}>
            <FontAwesomeIcon
                icon={solid('file-pdf')}
                size={'3x'}
                color={'#d53c3c'}
                onClick={() => handleExcelExportClick()}
                style={{cursor: 'pointer', marginTop: '0.1rem'}}
            />
            <div style={{display:"none"}} >
                <div ref={printRef}>
                    <p style={{textAlign:"center",fontSize:"2.3rem",backgroundColor:"gray",color:"whitesmoke",padding:"0.3rem"}}>월말 결산내역</p>
                    <p style={{marginLeft :"0.5rem"}}>{`조회기간 : ${startDate}~${endDate}`}</p>
                    <p style={{marginLeft :"0.5rem"}}>{`인쇄일시 : ${days().format('YYYY-MM-DD HH:mm:ss')}`}</p>
                    <table style={{width:'100%'}}>
                        <thead style={{backgroundColor:"black",color:"whitesmoke"}}>
                        <tr>
                            <th style={{textAlign:"center"}}>거래처명</th>
                            <th style={{textAlign:"center"}}>제품명</th>
                            <th style={{textAlign:"center"}}>수량</th>
                            <th style={{textAlign:"center"}}>공급가액</th>
                            <th style={{textAlign:"center"}}>세액</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableList && tableList.map((v,i)=>{
                            return (
                                <tr key={i} style={trStyleObj(v)}>
                                    <td>{v.partner_company_name}</td>
                                    <td>{v.semi_product_name}</td>
                                    <td style={{textAlign:"right"}}>{(v?.quantity ?? 0).toLocaleString()}</td>
                                    <td style={{textAlign:"right"}}>{(v?.price_supply ?? 0).toLocaleString()}</td>
                                    <td style={{textAlign:"right"}}>{(v?.price_tax ?? 0).toLocaleString()}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                </div>
            </div>

        </div>
    );
};

export default ClosingReportExcel241028;