import React from 'react';
import dayjs from "dayjs";

const DateRange = ({options}) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '0.5rem',
          alignItems: 'center',
            margin: "50px 0"
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
          <span style={{ fontWeight: 'bold', fontSize: '3.3rem' }}>{dayjs(options.baseDate).format('YYYY.MM')}</span>
          <div style={{ width: '10px', backgroundColor: '#6c757d' }}></div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <span>
            조회기간 : {dayjs(options.nowStartDate).format('YYYY. MM .DD')} ~{' '}
            {dayjs(options.nowEndDate).format('YYYY. MM .DD')}
          </span>
          <span>출력일시 : {dayjs().format('YYYY-MM-DD HH:mm:ss')}</span>
        </div>

        {/*{"nowStartDate":"2023-09-01","nowEndDate":"2023-09-13","preStartDate":"2022-09-01","preEndDate":"2022-09-13","thisYear":2023,"lastYear":2022,"baseDate":"2023-09-13"}*/}
      </div>
    )
};

export default DateRange;