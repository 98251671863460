import React, {useContext} from 'react';
import {ReducerDispatchContext, ReducerStateContext} from "../context";
import _ from 'lodash'

const ResultLossRpm = () => {

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);


    const getTdDataFnc = ({classType="",subulMonth=0,columnName="",subTotal=0})=>{
        let CL = state.myLossRpmTable ?? []

        if(subTotal === 1) {
            CL = CL.filter((f)=> f.sub_class_type === "누계" && f.main_class_type === classType)
        }else{
            CL = CL.filter((f)=> f.sub_class_type === "당월" && f.main_class_type === classType && f.subul_month === subulMonth )
        }

        console.log("CL",CL)
        if(CL.length > 0){
            return CL[0][columnName] ?? 0
        }else{
            return 0
        }

        // const tempValue = CL[0][columnName] ?? 0
        // return tempValue
    }
    
    const getTrComponent = ({classType="",columnName=""})=>{
        
        return (
          <tr style={{ backgroundColor: 'rgba(232,232,232,0.33)' }}>
            <td colSpan={2}>{classType}</td>
            <td>{getTdDataFnc({ classType: classType, subulMonth: 1, columnName: columnName,subTotal:1 })}</td>
            <td>{getTdDataFnc({ classType: classType, subulMonth: 1, columnName: columnName })}</td>
            <td>{getTdDataFnc({ classType: classType, subulMonth: 2, columnName: columnName })}</td>
            <td>{getTdDataFnc({ classType: classType, subulMonth: 3, columnName: columnName })}</td>
            <td>{getTdDataFnc({ classType: classType, subulMonth: 4, columnName: columnName })}</td>
            <td>{getTdDataFnc({ classType: classType, subulMonth: 5, columnName: columnName })}</td>
            <td>{getTdDataFnc({ classType: classType, subulMonth: 6, columnName: columnName })}</td>
            <td>{getTdDataFnc({ classType: classType, subulMonth: 7, columnName: columnName })}</td>
            <td>{getTdDataFnc({ classType: classType, subulMonth: 8, columnName: columnName })}</td>
            <td>{getTdDataFnc({ classType: classType, subulMonth: 9, columnName: columnName })}</td>
            <td>{getTdDataFnc({ classType: classType, subulMonth: 10, columnName: columnName })}</td>
            <td>{getTdDataFnc({ classType: classType, subulMonth: 11, columnName: columnName })}</td>
            <td>{getTdDataFnc({ classType: classType, subulMonth: 12, columnName: columnName })}</td>

          </tr>
        )
    }

    return (
       <>
           {getTrComponent({classType: "LOSS",columnName:"loss_ratio"})}
           {getTrComponent({classType: "RPM",columnName:"driving_rpm"})}
       </>
    );
};

export default ResultLossRpm;