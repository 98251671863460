import React,{useContext} from 'react';
import {ACTION_TYPES, ReducerStateContext,ReducerDispatchContext} from "../context";
import dayjs from "dayjs";
import ShowEachRow from "./ShowEachRow";
import EditEachRow from "./EditEachRow";
import Style from '../style/mainStyle.module.scss'

const MainTable = () => {

    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);

    return (
        <div className={`${Style.overflowTable}`}>
            <table>
                <colgroup>
                    <col width={'5%'}/>
                    <col width={'15%'}/>
                    <col width={'3%'}/>
                    <col width={'6%'}/>
                    <col width={'15%'}/>
                    <col width={'2%'}/>
                    <col width={'3%'}/>
                </colgroup>
                <thead>
                <tr>
                    <th>수주번호</th>
                    <th>제품명</th>
                    <th colSpan="1">수량</th>
                    <th>납기일시</th>
                    <th rowSpan="2">비고</th>
                    <th rowSpan="2">확정</th>
                    <th rowSpan="2"></th>
                </tr>
                <tr>
                    <th>거래처명</th>
                    <th>공정명</th>
                    {/*<th>납품단위</th>*/}
                    <th>자사단위</th>
                    {/*<th>원단단위</th>*/}
                    <th>등록일시</th>
                </tr>
                </thead>
                <tbody>
                {state.filteredTableList && state.filteredTableList.map((v) => {
                    // if(state.selectedRowId === v.rowId) {
                    //     return <EditEachRow v={v} />
                    // }
                    // return <ShowEachRow v={v}   />

                    return <EditEachRow v={v}/>
                })}

                </tbody>
            </table>
            <p style={{textAlign:"left"}}>(*) OP(Outsourced Process)</p>
        </div>
    );
};

export default MainTable;