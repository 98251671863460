import _ from 'lodash'
import dayjs from "dayjs";
import {userToast} from "helpers/userToast";
import {ExportToCsv} from "export-to-csv";

export const groupedTableList0830 = ({ beforeArray = [], monthList = [], startDate }) => {
    console.log("beforeArray",beforeArray)
  const CL1 = _.cloneDeep(beforeArray)
  let uniqArray = _.uniqBy(CL1, 'partner_company_id').map((v) => {
      return { partner_company_id: v.partner_company_id }
  })
  uniqArray = _.sortBy(uniqArray, 'partner_company_name')

  let groupedList = _.groupBy(CL1, (v) => [v.partner_company_id].join())

  const thisYear = Number(dayjs(startDate).format('YYYY'))
  const lastYear = thisYear - 1


    uniqArray = uniqArray.map((v)=>{
        let tempObj = v
        monthList.map((vv)=>{
            let nowAmount = 0
            let outAmount = 0
            const tempList = groupedList[v.partner_company_id]?.filter((f)=> {
                if(f.subul_year === thisYear && f.subul_month === vv){
                    return f
                }
            })
            if(tempList?.length > 0 ){
                nowAmount = tempList[0].now_amount ?? 0
                outAmount = tempList[0].out_amount ?? 0
                tempObj = {...tempObj, thisYearNotAmount : nowAmount,thisYearOutAmount : outAmount, thisYear,thisMonth:vv }
            }
        })

        monthList.map((vv)=>{
            let nowAmount = 0
            let outAmount = 0
            const tempList = groupedList[v.partner_company_id]?.filter((f)=> {
                if(f.subul_year === lastYear && f.subul_month === vv){
                    return f
                }
            })
            if(tempList?.length > 0 ){
                nowAmount = tempList[0].now_amount ?? 0
                outAmount = tempList[0].out_amount ?? 0
                tempObj = {...tempObj, lastYearNotAmount : nowAmount,lastYearOutAmount : outAmount, thisYear,lastMonth:vv }
            }
        })
        return tempObj
    })




  return uniqArray

}


export const getTableTdData= ({groupedList,columnName="",targetYear,targetMonth,partnerId})=>{
    const tempArray = groupedList[partnerId] ?? []
    if (tempArray.length > 0) {
      const filteredList = tempArray.filter((f) => f.subul_year === targetYear && f.subul_month === targetMonth)
      const subTotal = _.ceil(_.sumBy(filteredList, columnName) / 1000) ?? 0
      return subTotal
    } else {
      return 0
    }
}

export const separateEachTable = ({beforeArray=[],thisMonth=0})=>{
    const CL1 = _.cloneDeep(beforeArray)
    const CL2 = CL1.filter((f)=> f.subul_month === thisMonth)
    const CL3 = _.groupBy(CL1,"product_process_id")

    // console.log("CL1",CL1)
    // console.log("CL2",CL2)
    // console.log("CL3",CL3)
    return {detailAllList:beforeArray,detailThisMonth : CL2,detailGroupedByPp:CL3}
}


export const getTableTdSubtotal= ({baseList=[],columnName="",partnerCompanyId=0,targetYear=0})=>{
    const groupedList = _.cloneDeep(baseList)
    const tempArray = groupedList[partnerCompanyId] ?? []
    if(tempArray.length > 0 ){
        const filteredList = tempArray.filter((f)=>f.subul_year === targetYear)
        const subTotal = _.ceil(_.sumBy(filteredList,columnName)/1) ?? 0
        return subTotal
    }else{
        return 0
    }
}


export const excelExportStockList1102= ({baseList=[],})=>{

    if(baseList.length ===0) return userToast('error','엑셀로 변환할 대상이 존재하지 않습니다.')

    const CL = _.cloneDeep(baseList)
    const CL1 = _.sortBy(CL,['partner_company_id','subul_year','subul_month'], ['asc', 'asc', 'asc'])

    // console.log("baseList_15335_",baseList)
    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'My Awesome CSV',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    }
    const csvExporter = new ExportToCsv(options)

    const getStorageStatus = ({top=1,bottom=1})=>{
        if(top >0 && bottom >0){
            return (top /bottom)
        }
        return 0
    }

    const sujuList = CL1.reduce((newArray, newItem) => {
        const newObj = {
            거래처명: newItem.partner_company_name || '',
            연도: newItem.subul_year || '',
            월: newItem.subul_month || '',
            출고금액: newItem.out_amount || '',
            재고금액: newItem.now_amount || '',
            재고월수: getStorageStatus({top:newItem.now_amount,bottom:newItem.out_amount}) || '',
        }
        newArray.push(newObj)
        return newArray
    }, [])
    csvExporter.generateCsv(sujuList)
}

export const getTdBackgroundColor1102 = ({top=0,bottom=0}) =>{

    const getRatioFnc = (top=1,bottom=1)=>{
        if(top > 0 && bottom > 0) return _.ceil(top/bottom,1)
        return null
    }
    const tempValue = getRatioFnc(top, bottom) ?? 0
    if(tempValue > 2.0) return 'rgba(255,205,178,0.71)'
    if(tempValue > 1.0) return 'rgba(243,209,43,0.42)'
    if(tempValue > 0) return '#def3de'
    if(bottom === 0 && top > 0) return '#e7d3f3'
    return  ''
}