import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { decryptFunction } from './cryptoJS'

// 회사 코드 가져오기
export const userCompany = () => {
  const userInfo = sessionStorage.getItem('user')
  const obj = JSON.parse(userInfo)
  // const company_code = obj.company_code
  // return company_code

  // 2022.03.27 test
  const company_code2 = obj.company_code2
  const decryptedCompanyCode = decryptFunction(company_code2)
  return decryptedCompanyCode
}

export const getUserInfo = () => {
  const userInfo = sessionStorage.getItem('user')
  const obj = JSON.parse(userInfo)
  return obj
}

// getUserId : 로그인한 사용자 userId 가져오기
export const getUserId = () => {
  const userInfo = sessionStorage.getItem('user')
  const obj = JSON.parse(userInfo)
  // const userId = obj.user_id
  // return userId

  // 2022.03.27 test
  const userId2 = obj.user_id2
  const decryptedUserId = decryptFunction(userId2)
  return decryptedUserId
}

// getEmployeeId : 로그인한 사용자 getEmployeeId 가져오기
export const getEmployeeId = () => {
  const userInfo = sessionStorage.getItem('user')
  const obj = JSON.parse(userInfo)
  // const userId = obj.user_id
  // return userId

  // 2022.03.27 test
  const employee_id = obj.employee_id2
  const decryptedEmployee_id = decryptFunction(employee_id)
  return decryptedEmployee_id
}

// getUserPartnerCompanyId2 : 로그인한 사용자 타입이 C인경우 partner_company_id 가져오기
export const getUserPartnerCompanyId2 = () => {
  const userInfo = sessionStorage.getItem('user')
  const obj = JSON.parse(userInfo)

  const partnerCompanyId = obj.partner_company_id2
  const decryptedPartnerCompanyId = decryptFunction(partnerCompanyId)
  return decryptedPartnerCompanyId
}

// getDepartmentAdminYn : 사용자가 부서내 관리자인지 확인하는 방법
export const getDepartmentAdminYn = () => {
  const userInfo = sessionStorage.getItem('user')
  const obj = JSON.parse(userInfo)

  const departmentAdminYn = obj.encryptedDepartmentAdminYn
  const decryptedDepartmentAdminYn = decryptFunction(departmentAdminYn)
  return decryptedDepartmentAdminYn
}



// getUserType : 로그인한 사용자 userType 가져오기
export const getUserType = () => {
  const userInfo = sessionStorage.getItem('user')
  const obj = JSON.parse(userInfo)
  const user_type = obj.user_type
  return user_type
}

// 로그인한 사용자 이름 가져오기
export const getUserEmployeeName = () => {
  const userInfo = sessionStorage.getItem('user')
  const obj = JSON.parse(userInfo)
  const employee_name = obj.employee_name
  return employee_name
}

// 로그인한 회사의 로고 가져오기
export const getUserCompanyAvatarImage = () => {
  const url = 'https://allconnp-image.s3.ap-northeast-2.amazonaws.com/image/ace0c8d0a2ac-11ec-872f-0dc354646cfa.jpeg'
  return String(url)
}

// 사용자 이미지
export const getCompanyUserImageLink = async () => {
  const vv = await axios.post('/apps/systemManagement/awsImage/companyLogo/getCompanyUserImageLink', {
    headers: {
      'x-access-token': sessionStorage.getItem('token'),
    },
    companyCode: await getUserCompany(),
    getUserId: await getUserId(),
  })
  return vv.data
}

// 로그인한 회사의 로고 가져오기
export const getUserCompanyLogoImage = () => {
  const url = 'https://allconnp-image.s3.ap-northeast-2.amazonaws.com/image/d3890b80a29a-11ec-bd89-c1064c0311f4.jpeg'
  return String(url)
}

// 로그인한 사용자의 로고 가져오기( from  세션스토리지)
export const getUserCompanyLogoImageFromSessionStorage = () => {
  const imageLink = sessionStorage.getItem('companyLogoImageLink')
  const companyDefaultUserLink =
    'https://allconnp-image.s3.ap-northeast-2.amazonaws.com/image/a75947c0a2b1-11ec-9f67-b340bf687c8f.jpeg'

  if (sessionStorage.getItem('companyUserImageLink')) {
    return imageLink
  } else {
    return String(companyDefaultUserLink)
  }
}

export const getCompanyLogoLink = async () => {
  const vv = await axios.post('/apps/systemManagement/awsImage/companyLogo/getCompanyLogo', {
    headers: {
      'x-access-token': sessionStorage.getItem('token'),
    },
    companyCode: await getUserCompany(),
    getUserId: await getUserId(),
  })
  return vv.data
}

// 로그인한 사용자 회사의 로고 가져오기( from  세션스토리지)
export const getCompanyLogoLinkFromSessionStorage = () => {
  const imageLink = sessionStorage.getItem('companyLogoImageLink')
  const companyLogoImageLinkUrl =
    'https://allconnp-image.s3.ap-northeast-2.amazonaws.com/image/929ba9f0a2dd-11ec-b5e7-4b441b2075f0.png'

  if (sessionStorage.getItem('companyLogoImageLink')) {
    return imageLink
  } else {
    return String(companyLogoImageLinkUrl)
  }
}

// 로그인한 사용자 부서명 가져오기
export const getUserDepartmentName = () => {
  const userInfo = sessionStorage.getItem('user')
  const obj = JSON.parse(userInfo)
  const department_name = obj.department_name
  return department_name
}

// 로그인한 사용자 회사명 가져오기
export const getUserCompanyName = () => {
  const userInfo = sessionStorage.getItem('user')
  const obj = JSON.parse(userInfo)
  const company_name = obj.company_name
  return company_name
}

// 로그인 사용자가 partnerCompany 인경우(유저타입 C) partnerCompanyId를 가져오기!
export const getUserPartnerCompanyId = () => {
  const userInfo = sessionStorage.getItem('user')
  const obj = JSON.parse(userInfo)
  const partner_company_id = obj.partner_company_id
  return partner_company_id
}

// getUserCompany : 로그인한 사용자의 소속회사 코드 가져오기
export const getUserCompany = () => {
  const userInfo = sessionStorage.getItem('user')
  const obj = JSON.parse(userInfo)
  // const company_code = obj.company_code
  // return company_code

  // 2022.03.27 test
  const company_code2 = obj.company_code2
  const decryptedCompanyCode = decryptFunction(company_code2)
  return decryptedCompanyCode
}

// getUserDepartmentList : 로그인한 사용자의 부서 리스트 가져오기
export const getUserDepartmentList = async (v) => {
  const userInfo = sessionStorage.getItem('user')
  const obj = JSON.parse(userInfo)
  const userId = obj.user_id
  const userCompanyCode = await axios.post('/user/getUserDepartmentList', {
    headers: {
      'x-access-token': sessionStorage.getItem('token'),
    },
    userId: userId,
    userCompanyCode: v,
  })
  // console.log(userCompanyCode.data)
  return userCompanyCode.data
}

// getPartnerCompanyList : 로그인한 사용자와 거래중인 협력사 리스트(ex) 대상/풀무원
export const getPartnerCompanyList = async () => {
  // const partnerCompanyList = v; // v: 로그인한 사용자의 소속 회사 코드 ex B001 세미산업
  const partnerCompanyList =  getUserCompany()
  const getPartnerList = await axios.post('/company/getUserPartnerCompanyList', {
    headers: {
      'x-access-token': sessionStorage.getItem('token'),
    },
    companyCode: partnerCompanyList,
  })
  return getPartnerList?.data
}

// 거래처 코드가 없는 파트너 리스트
export const getPartnerCompanyListNoneCode = async () => {
  // const partnerCompanyList = v; // v: 로그인한 사용자의 소속 회사 코드 ex B001 세미산업
  const partnerCompanyList = getUserCompany()
  const getPartnerList = await axios.post('/company/getPartnerCompanyListNoneCode', {
    headers: {
      'x-access-token': sessionStorage.getItem('token'),
    },
    companyCode: partnerCompanyList,
  })
  return getPartnerList.data
}


export const getMaterialClassList = async () => {
  // const partnerCompanyList = v; // v: 로그인한 사용자의 소속 회사 코드 ex B001 세미산업
  const partnerCompanyList = getUserCompany()
  const apiResult = await axios.post('/company/getMaterialClassList', {
    headers: {
      'x-access-token': sessionStorage.getItem('token'),
    },
    companyCode: partnerCompanyList,
  })
  return apiResult.data
}

export const getMaterialList1220 = async () => {
  // 자재 코드 리스트 가져오기
  const partnerCompanyList = getUserCompany()
  const apiResult = await axios.post('/company/getMaterialList1220', {
    headers: {
      'x-access-token': sessionStorage.getItem('token'),
    },
    companyCode: partnerCompanyList,
  })
  return apiResult.data
}

export const getSalesList0105 = async (isEnable = 1) => {

  /* 영업코드 가져오기 2023.01.05
    isEnable === 1 사용중인 리스트만
    isEnable === 0 폐기 포함
   */

  const partnerCompanyList = getUserCompany()
  const apiResult = await axios.post('/company/getSalesList0105', {
    headers: {
      'x-access-token': sessionStorage.getItem('token'),
    },
    companyCode: partnerCompanyList,
    isEnable:isEnable
  })
  return apiResult.data
}

export const getProductList0105 = async (isEnable = 1) => {

  /* 영업코드 가져오기 2023.01.05
    isEnable === 1 사용중인 리스트만
    isEnable === 0 폐기 포함
   */

  const partnerCompanyList = getUserCompany()
  const apiResult = await axios.post('/company/getProductList0105', {
    headers: {
      'x-access-token': sessionStorage.getItem('token'),
    },
    companyCode: partnerCompanyList,
    isEnable:isEnable
  })
  return apiResult.data
}


// 0608 한울 추가
// 로그인한 사용자에서 자사가 아닌 협력사 리스트(my_company_yn이 0인 리스트) 추리기
export const getPartnerCompanyListWithoutMyCompany = async (companyCode = null, sqlType ="basic") => {
  let getUserCompany = companyCode
  if (!getUserCompany) {
    getUserCompany = userCompany()
  }
  // const partnerCompanyList = v; // v: 로그인한 사용자의 소속 회사 코드 ex B001 세미산업
  // const userCompany = await getUserCompany() // 이부분 중복이라 제거했습니다
  const getPartnerList = await axios.post('/company/getPartnerCompanyListWithoutMyCompany', {
    headers: {
      'x-access-token': sessionStorage.getItem('token'),
    },
    companyCode: getUserCompany,
    sqlType: sqlType,
  })
  return getPartnerList.data
}

// 로그인한 사용자의 직원(Employee) 리스트 가져오기
export const getEmployeeDepartmentAndEmployeeName = async () => {
  const companyCode = userCompany()

  const result = await axios.post('/user/getEmployeeDepartmentAndEmployeeName', { companyCode })
  return result.data
}

// getUserDepartmentAndUserName : 로그인한 사용자의 부서 & 사용자이름 (ex) 생산파트_김OO
export const getUserDepartmentAndUserName = async () => {
  const companyCode = userCompany()

  const getUserDepartmentAndUserName = await axios.post('/user/getUserDepartmentAndUserName', {
    headers: {
      'x-access-token': sessionStorage.getItem('token'),
    },
    companyCode: companyCode,
  })
  return getUserDepartmentAndUserName.data
}

// getMyPartnerCompanyId ---  테이블 : partner_company, 컬럼 : my_company_yn = 1
export const getMyPartnerCompanyId = async () => {
  const userId = getUserId()

  const resultData = await axios.post('/user/getMyPartnerCompanyId', {
    headers: {
      'x-access-token': sessionStorage.getItem('token'),
    },
    userId: userId,
  })

  if (resultData.data.length === 0) {
    return 0
  } else {
    const myPartnerCompanyId = resultData.data[0].partner_company_id
    return myPartnerCompanyId
  }
}

// 로그인한 사용자의 운영 ProductProcessId 가져오기
export const getUserProductProcessId = async (params = "onlyUse") => {
  const companyCode = userCompany()

  const result = await axios.post('/user/getUserProductProcessId', { companyCode,params })
  return result.data


  /* Params 유형 ************************************************************************************
    (*) onlyUse : 사용중인 제품만
    (*) allWithInfo : 사용 종료된 제품과 함께 제품정보를 같이 보여주는 API
  ************************************************************************************************ */

}

// 로그인한 사용자의 생산라인코드 가져오기
export const getUserProductLineInfo = async () => {
  const companyCode = userCompany()

  const result = await axios.post('/user/getUserProductLineInfo', { companyCode })
  return result.data
}

// 로그인한 사용자의 근무조 가져오기
export const getUserWorkingPartInfo = async () => {
  const companyCode = userCompany()

  const result = await axios.post('/user/getUserWorkingPartInfo', { companyCode })
  return result.data
}
