import React, {useState, useReducer, useRef} from 'react';
import {ACTION_TYPES,INIT_STATE, ReducerStateContext, ReducerDispatchContext, mainReducerActions} from "./context";
import SalesReportHeader from "./component/Header";
import YearlyTable from "./component/YearlyTable";
import YearlyTableGraph from "./component/YearlyTableGraph";
import Style from './style/table.module.scss'

const YearlySalesReport = () => {


    const [state,dispatch] = useReducer(mainReducerActions,INIT_STATE )

    const printRef = useRef(null)

    return (
        <ReducerStateContext.Provider value = {state}>
            <ReducerDispatchContext.Provider value = {dispatch}>
                <SalesReportHeader printRef={printRef}/>
                <YearlyTableGraph/>
                <YearlyTable />
            </ReducerDispatchContext.Provider>
        </ReducerStateContext.Provider>

    );
};

export default YearlySalesReport;