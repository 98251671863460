import React,{useContext} from 'react';
import {ACTION_TYPES, ReducerStateContext,ReducerDispatchContext} from "../context";
import dayjs from "dayjs";
import _ from 'lodash'
import {Badge, Input, Table} from "reactstrap";

const BodyTableSalesResult241020 = () => {
    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);

    const handleEachRowChange= ({rowId,columnName,tempValue})=> {
        let CL = state.filteredTableList
        const findIndex = _.findIndex(CL, {rowId: rowId})
        const baseObj = {...CL[findIndex], [columnName]: tempValue}


        if (columnName === "deleteYn") {
            baseObj.deleteYn = 1
        } else {
            baseObj.editedYn = 1
        }

        if (columnName === "out_price") {
            baseObj.price_supply = _.round(((baseObj.order_size * baseObj.quantity) * tempValue) ?? 0,0)
            baseObj.price_tax = _.round(baseObj.price_supply / 10,0)
        }else if(columnName === "price_supply") {
            baseObj.price_tax = _.round(tempValue / 10,0)
        }else{

        }

        CL[findIndex] = {...CL[findIndex], ...baseObj}

        dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName: "filteredTableList", value: CL})
    }

    const plainInputComponent = ({type = "date", columnName, data}) => {
        const handleInputChange =(e)=>{
            const tempValue = e.target.value;
            handleEachRowChange({rowId:data.rowId,columnName,tempValue})
        }
        return (
            <Input type={type} value={data[columnName]} onChange={handleInputChange} disabled={data.confirm_yn === 0} />
        )
    }

    const checkboxInputComponent = ({columnName,data})=>{
        const handleInputChange = () =>{
            const tempValue = data[columnName] === 1 ? 0 : 1
            handleEachRowChange({rowId:data.rowId,columnName,tempValue})
        }

        return (
            <td style={{textAlign: "center", verticalAlign: "middle", backgroundColor:(data.confirm_yn === 1 ? "#d2f6ec" :"#f8e3e7")}}>
                    <input type={"checkbox"} checked={data[columnName] === 1} style={{zoom:2}} onChange={handleInputChange}/>
            </td>
        )
    }

    return (
        <div style={{width: "100%", minHeight: "35vh", marginTop: "2rem" , height:"35vh",overflow:"auto"}}>
            <Table size="sm" hover={true} bordered={true} >
                <colgroup>
                    <col width={'3%'}/>
                    <col width={'5%'}/>
                    <col width={'6%'}/>
                    <col width={'30%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'5%'}/>
                    <col width={'1%'}/>
                </colgroup>
                <thead style={{position: "sticky", top: "2px", zIndex: 5,background:"white"}}>
                <tr>
                    <th>No</th>
                    <th>수주번호</th>
                    <th>고객사명</th>
                    <th>제품명</th>
                    <th>수불일자</th>
                    <th>생산일자</th>
                    <th>규격사이즈</th>
                    <th>수량</th>
                    <th>단가</th>
                    <th>공급가액</th>
                    <th>세액</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {state.filteredTableList && state.filteredTableList.map((v, i) => {
                    return (
                        <tr key={v.rowId}
                            style={{backgroundColor:(v.rowId === state?.selectedRowObj?.rowId) ? "lightgrey" : (v.deleteYn === 1 ? "lightpink" : "")}}
                            onClick={()=>{
                            dispatch({type: ACTION_TYPES.ARRAY_CHANGE, columnName: "selectedRowObj", value: v})
                        }}>
                            <td>{i + 1}</td>
                            <td>{v.suju_number}</td>
                            <td>{v.partner_company_name}</td>
                            <td>{v.semi_product_name}</td>
                            <td>{dayjs(v.subul_date).format("YYYY-MM-DD")}</td>
                            <td>{dayjs(v.product_date).format("YYYY-MM-DD")}</td>
                            <td>{v.order_size}</td>
                            <td>{v.quantity}</td>
                            <td>{plainInputComponent({type: "text", columnName: "out_price", data: v})}</td>
                            <td>{plainInputComponent({type: "text", columnName: "price_supply", data: v})}</td>
                            <td>{plainInputComponent({type: "text", columnName: "price_tax", data: v})}</td>
                            <td><Badge color={"danger"} onClick={()=>handleEachRowChange({rowId:v.rowId,columnName:'deleteYn',tempValue:0})}>삭제</Badge></td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </div>
    );
};

export default BodyTableSalesResult241020;