import React, {useContext, useEffect, useState} from 'react';
import Style from '../style/dashboard.module.scss'
import {getBaseDate} from "../function";
import dayjs from "dayjs";
import {ReducerDispatchContext, ReducerStateContext} from "../context";
import {ACTION_TYPES} from "../../../monthlyClosing/report/manufacturingCost/context";
import SalesDailyInfo0911 from "./SalesDailyInfo0911";
import SujuDailyInfo0912 from "./SujuDailyInfo0912";
import ReturnDailyInfo0912 from "./ReturnDailyInfo";
import ResultMyDaily0912 from "./ResultMyDaily";
import ProductConfirm0912 from "./ProductConfirm0912";
import SujuCancel0912 from "./SujuCancel0912";
import SalesPriceCheck from "./SalesPriceCheck";
import ResultLength0907 from "../../../monthlyClosing/report/resultLengh/ResultLength";
import YearlySalesReport from "../../../monthlyClosing/report/sales/YearlyTable";
import DateRange from "./DateRange";
import SujuCancelMaterial0912 from "./SujuCancelMaterial0915";

const DashBoardBody = ({printRef}) => {
    const state = useContext(ReducerStateContext); // 5
    const dispatch = useContext(ReducerDispatchContext);

    const [options,setOptions] = useState({})


    useEffect(()=>{
        const initObj = getBaseDate({baseDate:state.headerOptions.baseDate})
        setOptions(initObj)
    },[state.headerOptions.baseDate])

    return (
        <div className={`${Style.DashBoardBody} printArea `} ref={printRef}>

            <DateRange  options={options} />
            <h1 className={`${Style.mainTitle}`}>경영지표 점검 ({dayjs(options.baseDate).format("MM/DD")}일 )</h1>
            <div className={`${Style.dashBoardRow}`}>
                <div style={{display:"flex",flexDirection:"column"}}>
                    <div style={{display:"flex",flexDirection:"row"}}>
                        <SalesDailyInfo0911 options={options} />
                        <SujuDailyInfo0912 options={options} />
                    </div>
                    <div style={{display:"flex",flexDirection:"row"}}>
                        <ReturnDailyInfo0912 options={options} />
                        <ResultMyDaily0912 options={options} />
                    </div>
                </div>
            </div>
            <h1 className={`${Style.mainTitle}`}>수주점검</h1>
            <div className={`${Style.dashBoardRow}`}>
                {/*<ProductConfirm0912/>*/}
                <SujuCancel0912 options={options} />
                <SujuCancelMaterial0912 options={options} />
            </div>

            <h1 className={`${Style.mainTitle}`}>영업점검</h1>
            <div className={`${Style.dashBoardRow}`}>
                <SalesPriceCheck options={options} />
            </div>

            <h1 className={`${Style.mainTitle}`}>매출실적</h1>
            <div className={`${Style.dashBoardRow}`}>
                <YearlySalesReport />
            </div>
            
            <h1 className={`${Style.mainTitle}`}>생산실적</h1>
            <div className={`${Style.dashBoardRow}`}>
                <ResultLength0907 />
            </div>
            
        </div>
    );
};

export default DashBoardBody;